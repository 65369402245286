import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../ui/Button'
import Icon from '../../../ui/Icon';

import classes from '../style/general.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest } from '../../../actions/authActions';
import { getClientOrderDonorRequest, getLastOrderCoinsRequest } from '../../../actions/userActions';
import { sendPaymentsRequest } from '../../../actions/globalActions';

const General = () => {
    const dispatch = useDispatch();
    const orderDonorInfo = useSelector((state) => state.user.orderDonorInfo);
    const lastOrderCoinPack = useSelector((state) => state.user.lastOrderCoinPack);
    const user = useSelector((state) => state.user.user);

    const onLogOut = async () => {
        try {
            await dispatch(logoutRequest({
                onSuccessRedirect: (url) => window.location.href = url,
            }));
        } catch (error){
            console.error('error', error);
        }
    };

    useEffect(() => {
        if(user && !orderDonorInfo){
            dispatch(getClientOrderDonorRequest(user.id))
        }
    }, [orderDonorInfo, user]);

    useEffect(() => {
        if(user && !lastOrderCoinPack){
            dispatch(getLastOrderCoinsRequest(user.id))
        }
    }, [lastOrderCoinPack, user]);

    const orderDonorPayment = () => {
        let data = {
            donorPackageId: orderDonorInfo?.order.id,
            amount: orderDonorInfo?.order.price,
            success_url: window.location.href
        }

        dispatch(
            sendPaymentsRequest(data, (response, error) => {
                if (response) {
                    window.open(response, '_self');
                } else {
                    console.error("Error order donor payment:", error);
                }
            })
        );

    }

    return (
        <div className={classes['settings-general']}>
            <h4>PACKAGE</h4>
            {
                orderDonorInfo && orderDonorInfo.order ? (
                    <>
                        <p className={classes['sm-title']}>Package <Link to={`/packages/${orderDonorInfo?.order.name}`} >{orderDonorInfo?.order.name}</Link> ordered for donor <Link to={`/donor/${orderDonorInfo?.donorName.toLowerCase()}-${orderDonorInfo?.donorId}`}>{orderDonorInfo?.donorName}</Link></p>
                        <div className={classes['program-block']}>
                            <div className={classes['programs-item']}>
                                <div className={classes['col']}>
                                    <div className={classes['icon']}>
                                        <img src={`/cdn/images/programs/${orderDonorInfo?.order.name}.png`} alt="" />
                                    </div>
                                    <div className={classes['info']}>
                                        <p className={classes['name']}>{orderDonorInfo?.order.name}</p>
                                        <p className={classes['short-info']}>{orderDonorInfo?.order.shortDescription}</p>
                                    </div>
                                </div>
                                <div className={classes['order-info']}>
                                    <p className={classes['price']}><span>€</span>{orderDonorInfo?.order.price}</p>
                                    <p className={classes['pays']}>/first payments</p>
                                    <Button onClick={orderDonorPayment} addClasses={['small']}>Payment</Button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <p className={classes['sm-title']}><Link to="/donors">Choose donor</Link> for order</p>
                )
            }
            <h4>Coins</h4>
            {
                lastOrderCoinPack ? (
                    <>
                        <p className={classes['sm-title']}>Your last coin purchase</p>
                        <div className={classes['balance-block']}>
                            <div className={classes['balance-info']}>
                                <p className={classes['name']}>{lastOrderCoinPack?.name}</p>
                                <div className={classes['info']}>
                                    <p><Icon id="icon-ok-round" />{Math.trunc(lastOrderCoinPack?.count/10)} Emails</p>
                                    or
                                    <p><Icon id="icon-ok-round" />{Math.trunc(lastOrderCoinPack?.count/20)} Chat Messages</p>
                                    or
                                    <p><Icon id="icon-ok-round" />{Math.trunc(lastOrderCoinPack?.count/100)} Video Chats min</p>
                                </div>
                            </div>
                            <div className={classes['price-block']}>
                                <p className={classes['price']}><sup>€</sup>{lastOrderCoinPack?.price}</p>
                                <p className={classes['coins']}>{lastOrderCoinPack?.count} coins</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <p className={classes['sm-title']}><Link to="/coins">Choose coins package</Link></p>
                )
            }
            {/* <h4>LANGUAGE</h4>
            <div className={classes['select-block']}>
                <label htmlFor="lang">Select language</label>
                <div className={classes['select']}>
                    <select name="lang" id="lang" defaultValue="en">
                        <option value="en">English</option>
                        <option value="ua">Ukranian</option>
                        <option value="sp">Spanish</option>
                        <option value="gm">German</option>
                        <option value="it">Italian</option>
                    </select>
                    <div className={classes["image"]}>
                        <Icon id="icon-arrow-down" className="icon-arrow-down" />
                    </div>
                </div>
            </div>
            <h4>DELETE Account</h4>
            <Button addClasses={['capitalize', 'default']}>Delete Account</Button> */}
            <h4>Log Out from Account</h4>
            <Button addClasses={['capitalize', 'default']} onClick={onLogOut}>Log Out</Button>
        </div>
    );
}

export default General;
