import React, { useState } from "react";
import classes from "../style/contactSection.module.scss";
import Button from "../../../ui/Button";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { submitContactFormRequest } from "../../../actions/userActions";
import { sendToDataLayer } from "../../../utils/dataLayer";

const ContactSection = () => {
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();
    const [showAfterForm, setShowAfterForm] = useState(false);

    const onSubmit = data => {
        try {
            dispatch(submitContactFormRequest(data));
            sendToDataLayer('Form_main_Contact_us', 'client', 'Form_main_Contact_us', 'user', 1)
            setShowAfterForm(true);
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };
    
    return (
        <section className={classes["contact"]}>
            <div className="container">
                <div className={classes["contact_block"]}>
                    {showAfterForm ? (
                        <h2>Thank You for Contacting Us!</h2>
                    ) : (
                            <form
                                className={classes["contact_form"]}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <h2>Contact us</h2>
                                <label className={classes["contact_label"]} for="name">
                                    Your Name*
              </label>
                                <input
                                    className={classes["contact_input"]}
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Enter your name"
                                    {...register("name", { required: true })}
                                />
                                <label className={classes["contact_label"]} for="phone">
                                    Your Phone
                                </label>
                                <input
                                    className={classes["contact_input"]}
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    placeholder="Enter your phone"
                                    {...register("phone", { required: false })}
                                />
                                <label className={classes["contact_label"]} for="email">
                                    Your Email*
                                </label>
                                <input
                                    className={classes["contact_input"]}
                                    type="text"
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    {...register("email", { required: true })}
                                />
                                <label className={classes["contact_label"]} for="select_option">
                                    Your subject*
                                </label>
                                <input
                                    className={classes["contact_input"]}
                                    type="text"
                                    id="select_option"
                                    name="select_option"
                                    placeholder="Your subject"
                                    {...register("subject", { required: true })}
                                />
                                <label className={classes["contact_label"]} for="message">
                                    Message
                                </label>
                                <textarea
                                    className={classes["contact_input"]}
                                    id="message"
                                    name="message"
                                    placeholder="Write us a message"
                                    {...register("message", { required: false })}
                                ></textarea>
                                <Button type="submit" addClasses={["contact_us"]}>
                                    SEND
                                </Button>
                            </form>
                        )}
                    <div className={classes["contact_image"]}>
                        <img src="/cdn/images/main-page/contact_image.jpg" alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;
