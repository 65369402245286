import React, { useCallback, useContext, useEffect, useState } from "react";
import Button from "../../ui/Button";
import { fetchOnlineDonorsRequest, setAvailableSeconds, setChatConnection } from "../../actions/videoChatActions";
import { useDispatch, useSelector } from "react-redux";
import { sendConversationRequest } from "../../actions/globalActions";
import { SocketContext } from "../../context/socket";

import classes from './style/videochatButton.module.scss'
import { useNavigate, useParams } from "react-router-dom";
import { useGetActiveDonor } from "../../hooks/onlineDonors";
import ACTIONS from "../../utils/actions";
import { fetchUserRequestAcction } from "../../actions/userActions";
import { fetchPopup } from "../../actions/modalActions";
import { isMobile } from "react-device-detect";
import { sendToDataLayer } from "../../utils/dataLayer";

export default function VideochatButton({ type, donorId, donorName }) {
    const { roomId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const socket = useContext(SocketContext);
    const activeDonor = useGetActiveDonor(donorId);

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const user = useSelector((state) => state.user.user);
    const chatConnection = useSelector((state) => state.videoChat.chatConnection);

    const [timer, setTimer] = useState(null);

    const handleTimer = useCallback(() => {
        if (timer !== null) {
            if (timer > 0) {
                setTimer((prevTimer) => prevTimer - 1);
            }
        }
    }, [timer]);

    useEffect(() => {
        if (activeDonor && activeDonor.timer) {
            setTimer(activeDonor.timer);
        }else if(activeDonor && !activeDonor.timer){
            setTimer(null);
        }
    }, [activeDonor]);

    useEffect(() => {
        let timerInterval;
        if (timer !== null) {
            timerInterval = setInterval(handleTimer, 1000);
        }

        return () => {
            if (timerInterval) {
                clearInterval(timerInterval);
            }
        };
    }, [timer, handleTimer]);

    const translatorResponse = useCallback((res) => {
        dispatch(setChatConnection(false))
        if(res.responsType == 'approved' && activeDonor){
            dispatch(setAvailableSeconds(res.calculatedSeconds.count));
            navigate(`/videochat/${activeDonor?.chatId}/${activeDonor?.roomId}`)
        }else {
            dispatch(fetchOnlineDonorsRequest());
        }
    }, [activeDonor, user]);

    useEffect(() => {
        socket.on("translator-response", (res) => translatorResponse(res));
        
        return () => {
            socket.off("translator-response");
        };

    }, [socket]);
    
    const startVideoCall = (type, pageType) => {
        if(activeDonor){
            if(type == 'start') {
                if (pageType == 'profile') {
                      sendToDataLayer('Button_videochat', 'client', 'Button_videochat', 'user', activeDonor.donorId)             
                }
                const data = {
                    senderClientId: user?.id,
                    donorId: activeDonor.donorId,
                    type: "CHAT",
                    hasVideo: true,
                };
                dispatch(
                    sendConversationRequest(data, (response, error) => {
                        if (response) {
                            const videoChatData = {
                                chatId: response.item.id,
                                userId: user?.id,
                                donorId: activeDonor.donorId,
                                name: user?.name,
                                photo: user?.userAvatar,
                                donorName: activeDonor.userName
                            }
                            socket.emit('request-to-translator', videoChatData)
                            dispatch(setChatConnection(true))                 
                        } else {
                            console.error("Error:", error);
                        }
                    })
                );
            }else {
                socket.emit(ACTIONS.LEAVE, {userId: user.id, donorId: activeDonor.donorId});
                window.open('/videochat', '_self')
                dispatch(setAvailableSeconds(0));
                dispatch(fetchUserRequestAcction(user.id))
            }
        }else if(!userInfo || (user && !user?.isLoggedIn)) {
            navigate('/login')
        }else {
            dispatch(fetchPopup({
                popupType: 'DonorLeaveChat',
                hideClose: true, 
                props: {
                    className: "leave-chat",
                    donorName: donorName,
                    buttonText: "OK"
                },
            }))
        }
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    };

    const setBtns = () => {
        if (timer !== null && timer > 0) {
            const buttonText = formatTime(timer);

            switch (type) {
                case "card":
                    return (
                        <button
                            className={classes["card_chat_button"]}
                            onClick={() => startVideoCall("start")}
                            disabled={true}
                        >
                            {buttonText}
                        </button>
                    );
                case "chat":
                    return (
                        <Button
                            addClasses={["button", "light", "capitalize"]}
                            disabled={true}
                            onClick={() => startVideoCall(roomId ? "end" : "start")}
                        >
                            {buttonText}
                        </Button>
                    );
                case "profile":
                    return (
                        <Button
                            addClasses={["button", "outline-rounded", "capitalize"]}
                            disabled={true}
                            onClick={() => startVideoCall("start", 'profile')}
                        >
                            {buttonText}
                        </Button>
                    );
                default:
                    return null;
            }
        }

        switch (type) {
            case "card":
                return (
                    <button
                        className={classes["card_chat_button"]}
                        onClick={() => startVideoCall("start")}
                    >
                        Video Chat
                    </button>
                );
            case "chat":
                return (
                    <Button
                        addClasses={["button", "light", "capitalize"]}
                        disabled={chatConnection}
                        onClick={() => startVideoCall(roomId ? "end" : "start")}
                    >
                        {
                            isMobile ?
                                roomId ? "End Chat" : "Start Chat"
                            :
                            roomId ? "End Video Chat" : "Start Video Chat"
                        }
                    </Button>
                );
            case "profile":
                return (
                    <Button
                        addClasses={["button", "outline-rounded", "capitalize"]}
                        onClick={() => startVideoCall("start")}
                    >
                        Video Chat
                    </Button>
                );
            default:
                return null;
        }
    };

    return <>{setBtns()}</>;
}
