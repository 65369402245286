import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation
} from "react-router-dom";
import { Provider } from 'react-redux'; 
import store from '../../store/store'; 
import {SocketContext, socket} from '../../context/socket';
import Register from "../../pages/Register";
import MainPage from "../../pages/MainPage";
import DonorsGalery from "../../pages/DonorsGalery";
import SingleDonorPage from "../../pages/SingleDonorPage";
import NotFoundPage from "../../pages/NotFoundPage";
import Textchat from "../../pages/Textchat";
import VideoChat from "../../pages/VideoChat";
import Mailbox from "../../pages/Mailbox";
import SavedDonorsPage from "../../pages/SavedDonorsPage";
import ChatProvider from "../../context/chatProvider";
import SettingsPage from "../../pages/SettingsPage";
import ComparedDonorsPage from "../../pages/ComparedDonorsPage";
// import CalendarPage from "../../pages/CalendarPage";
import ClientProfilePage from "../../pages/ClientProfilePage";
import PackagesPage from "../../pages/PackagesPage";
import DonorProgramsPage from "../../pages/DonorProgramsPage";
import SingleProgramPage from "../../pages/SingleProgramPage";
import BlogPage from "../../pages/BlogPage";
import SingleBlogPage from "../../pages/SingleBlogPage";
import PrivacyPolicyPage from "../../pages/StaticPages/components/PrivacyPolicyPage";
import TermsAndConditions from "../../pages/StaticPages/components/TermsAndConditions";
import TripsToClinicPage from "../../pages/StaticPages/components/TripsToClinicPage";
import EggDonorsTripsPage from "../../pages/StaticPages/components/EggDonorsTripsPage";
import SurrogateTripsPage from "../../pages/StaticPages/components/SurrogateTripsPage";
import { useEffect } from "react";
import LikedDonorsPage from "../../pages/LikedDonorsPage";
import AboutUsPage from "../../pages/StaticPages/components/AboutUsPage";
import ContactUsPage from "../../pages/StaticPages/components/ContactUsPage";
import QuickStart from "../../pages/StaticPages/components/QuickStart";
import ProgramStagesPage from "../../pages/StaticPages/components/ProgramStagesPage";
import DonorRequestPage from "../../pages/DonorRequestPage";
import DonorRegistrationPage from "../../pages/DonorRegistrationPage";
import TripToChildbirthPage from "../../pages/StaticPages/components/TripToChildbirthPage/TripToChildbirthPage";
import LegislationPage from "../../pages/StaticPages/components/LegislationPage/LegislationPage";
import Post1 from "../../pages/SingleBlogPage/components/Post-1";
import Post2 from "../../pages/SingleBlogPage/components/Post-2";
import Post3 from "../../pages/SingleBlogPage/components/Post-3";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const AppWrapper = () => {
    return (
        <SocketContext.Provider value={socket}>
            <Provider store={store}>
                <Router>
                    <ChatProvider>
                        <ScrollToTop />
                        <Routes>
                            <Route path="/" element={<MainPage />} />
                            <Route path="/login" element={<Register />} />
                            <Route path="/videochat" element={<VideoChat />} />
                            <Route path="/videochat/:chatId" element={<VideoChat />} />
                            <Route path="/videochat/:chatId/:roomId" element={<VideoChat />} />
                            <Route path="/donors" element={<DonorsGalery />} />
                            <Route path="/donors/:donorsType" element={<DonorsGalery />} />
                            <Route path="/donors/page/:pageNumber" element={<DonorsGalery />} />
                            <Route path="/donor/:donorParams" element={<SingleDonorPage />} />
                            <Route path="/textchat" element={<Textchat />} />
                            <Route path="/textchat/:chatId" element={<Textchat />} />
                            <Route path="/mailbox" element={<Mailbox />} />
                            <Route path="/mailbox/:box" element={<Mailbox />} />
                            <Route path="/mailbox/readletter/:mailId" element={<Mailbox />} />
                            <Route path="/saved-donors" element={<SavedDonorsPage />} />
                            <Route path="/favourite-donors" element={<LikedDonorsPage />} />
                            <Route path="/compared-donors" element={<ComparedDonorsPage />} />
                            <Route path="/settings" element={<SettingsPage />} />
                            <Route path="/settings/:path" element={<SettingsPage />} />
                            {/* <Route path="/calendar" element={<CalendarPage />} /> */}
                            <Route path="/profile" element={<ClientProfilePage />} />
                            <Route path="/coins" element={<PackagesPage />} />
                            <Route path="/packages" element={<DonorProgramsPage />} />
                            <Route path="/packages/:program" element={<SingleProgramPage />} />
                            <Route path="/blog" element={<BlogPage />} />
                            <Route path="/blog/page/:pageNumber" element={<BlogPage />} />
                            <Route path="/blog/are-genes-passed-on-from-the-surrogate-mother-to-the-child" element={<Post1 />} />
                            <Route path="/blog/what-will-the-baby-look-like-when-using-donor-oocytes" element={<Post2 />} />
                            <Route path="/blog/surrogacy-and-egg-donation" element={<Post3 />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                            <Route path="/trips-to-clinic" element={<TripsToClinicPage />} />
                            <Route path="/egg-donor-trips" element={<EggDonorsTripsPage />} />
                            <Route path="/surrogate-trips" element={<SurrogateTripsPage />} />
                            <Route path="/trip-to-childbirth" element={<TripToChildbirthPage />} />
                            <Route path="/about-us" element={<AboutUsPage />} />
                            <Route path="/contact-us" element={<ContactUsPage />} />
                            <Route path="/quick-start" element={<QuickStart />} />
                            <Route path="/program-stages" element={<ProgramStagesPage />} />
                            <Route path="/sperm-donor-request" element={<DonorRequestPage type="sperm" />} />
                            <Route path="/egg-donor-request" element={<DonorRequestPage type="eggs" />} />
                            <Route path="/surrogate-mother-request" element={<DonorRequestPage type="surrogacy" />} />
                            <Route path="/full-registration/step1" element={<DonorRegistrationPage />} />
                            <Route path="/full-registration/step2" element={<DonorRegistrationPage />} />
                            <Route path="/full-registration/step3" element={<DonorRegistrationPage />} />
                            <Route path="/full-registration/step4" element={<DonorRegistrationPage />} />
                            <Route path="/legislation" element={<LegislationPage />} />
                            <Route path="*" element={<NotFoundPage />} />
                            {/* <Route path="/" element={isLoggedIn ? <AppRoutes /> : <Navigate to="/login" />} /> */}
                        </Routes>
                    </ChatProvider>
                </Router>
            </Provider>
        </SocketContext.Provider>
    );
};

export default AppWrapper;
