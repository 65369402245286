import React from 'react';

import Pagination from '../../components/Pagination'
import BlogItem from './components/BlogItem';
import classNames from 'classnames';
import classes from './style/blogPage.module.scss'
import Icon from '../../ui/Icon';
import PageWrap from '../../app/components/PageWrap';

const BlogPage = () => {
    // const onPageChange = () => {
    //     console.log('onPageChange');
    // }

    const blogData = [
        {
          date: "12.04.2024",
          title: "Are Genes Passed on from the Surrogate Mother to the Child?",
          shortDeescription:
            'Wisdom says: "It is not the mother who gave birth, but the mother who brought up." It seems that humanity has decided to follow this folk wisdom, leading to the invention of surrogacy.',
          imageUrl: "/cdn/images/news_image_1.jpg",
          categories: ["Egg Donor"],
          link: '/blog/are-genes-passed-on-from-the-surrogate-mother-to-the-child'
        },
        {
          date: "15.05.2024",
          title: "What Will the Baby Look Like When Using Donor Oocytes?",
          shortDeescription:
            "Any baby gets half of its genes from its mother and half from its father. Therefore, if you use donor eggs, your baby will probably look very much like his or her father.",
          imageUrl: "/cdn/images/news_image_2.jpg",
          categories: ["Egg Donor"],
          link: '/blog/what-will-the-baby-look-like-when-using-donor-oocytes'
        },
        {
          date: "23.06.2024",
          title: "Surrogacy and Egg Donation: How to Choose a Donor and Organize a Surrogacy Program",
          shortDeescription:
            "Surrogacy and egg donation are becoming increasingly popular methods for couples and individuals who have difficulty conceiving a...",
          imageUrl: "/cdn/images/news_image_3.jpg",
          categories: ["Egg Donor"],
          link: '/blog/surrogacy-and-egg-donation'
        },
    ];

    return (
        <PageWrap>
            <section className={classes["blog"]}>
                <div className={classNames(classes["container"], "container")}>
                    <p className={classes['blog-text']}>Welcome to our Blog. Here you can find answers to your questions, learn more about our company and get the latest news about innovations in reproductive technologies.</p>
                    <div className={classes["blog_header"]}>
                        <div className={classes["blog_categories"]}>
                            <div className={classNames(classes["blog_category_pill"], classes["blog_category_pill_cat_1"], classes["blog_category_pill_cat_1_active"])}>
                                Egg Donor
                            </div>
                        </div>
                        {/* <div className={classes["blog_search"]}>
                            <Icon id="icon-search" className="icon-search" />
                            <input type="text" placeholder="Search" />
                        </div> */}
                    </div>
                    <div className={classes["blog_cards"]}>
                        {
                            blogData && blogData.map((post, index) => (
                                <BlogItem blogItem={post} />
                            ))
                        }
                    </div>
                    {/* <Pagination curPage={1} onPageChange={onPageChange} total={12} pageLink="/blog" /> */}
                </div>
            </section>
        </PageWrap>

    );
}

export default BlogPage;
