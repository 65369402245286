import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "../../../ui/Icon";

import {
    fetchChatsRequest,
    fetchNotificationsRequest,
    updateNotificationRequest,
} from "../../../actions/textChatActions";
import { ChatState } from "../../../context/chatProvider";

import classes from "../style/videoChatList.module.scss";
import classNames from "classnames";
import { SocketContext } from "../../../context/socket";
import ACTIONS from "../../../utils/actions";
import { sendConversationRequest } from "../../../actions/globalActions";

const VideoChatList = ({ chatId, notificationCount, onShowNotifyPopup }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { roomId } = useParams();
    const user = useSelector((state) => state.user.user);
    const videoChat = useSelector((state) => state.videoChat.onlineDonors);
    const messageNotification = useSelector((state) => state.textChats.notifications);
    const [unreadMessages, setUnreadMessages] = useState({});
    const socket = useContext(SocketContext);

    const { selectedChat, setSelectedChat, setCurrentTextChat } = ChatState();

    useEffect(() => {
        if (!videoChat) {
            dispatch(fetchChatsRequest());
            dispatch(fetchNotificationsRequest());
        }
    }, [dispatch, videoChat]);
    
    const getChatById = () => {
        return videoChat.find((chat) => chat.chatId == chatId);
    };
    
    useEffect(() => {
        if (chatId && videoChat) {
            setSelectedChat(chatId);
            setCurrentTextChat(getChatById());
        } else {
            setSelectedChat('');
            setCurrentTextChat(null);
        }
    }, [chatId, videoChat]);

    const showActiveChat = (currentChat) => {
        if(roomId) {
            socket.emit(ACTIONS.LEAVE)
            return;
        }
        if(notificationCount && notificationCount < 20){
            onShowNotifyPopup()
        }else {
            if(currentChat.chatId){
                setCurrentTextChat(currentChat);
                navigate(`/videochat/${currentChat.chatId}`);
                if (unreadMessages) {
                    dispatch(updateNotificationRequest(currentChat.chatId));
                }
            }else {
                const data = {
                    senderClientId: user?.id,
                    donorId: currentChat.donorId,
                    type: "CHAT",
                    hasVideo: true,
                };
                dispatch(
                    sendConversationRequest(data, (response, error) => {
                        if (response) {
                            dispatch(fetchChatsRequest());
                            window.open(`/videochat/${response.item.id}`, '_self');              
                        } else {
                            console.error("Error:", error);
                        }
                    })
                );
            }
        }
    };

    useEffect(() => {
        if(chatId){
            dispatch(fetchChatsRequest());
            dispatch(fetchNotificationsRequest());
        }
    }, [chatId]);

    useEffect(() => {
        const handleInviteAccepted = (mes) => {
            dispatch(fetchChatsRequest());
            setUnreadMessages((prevUnreadMessages) => {
                const updatedUnreadMessages = { ...prevUnreadMessages };

                mes.messageNotification.forEach((notification) => {
                    if (notification.chatId !== parseInt(selectedChat, 10)) {
                        updatedUnreadMessages[notification.chatId] = notification.count;
                    }
                });

                return updatedUnreadMessages;
            });
        };

        const unreadMessagesObj = {};
        messageNotification.forEach((notification) => {
            unreadMessagesObj[notification.chatId] = notification.count;
        });
        setUnreadMessages(unreadMessagesObj);

        socket.on("notification", handleInviteAccepted);

        return () => {
            socket.off("notification", handleInviteAccepted);
        };
    }, [messageNotification, socket]);

    const formatDate = (originalDate) => {
        const date = new Date(originalDate);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
    
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    
        return `${formattedHours}:${formattedMinutes}${ampm}`;
    }

    return (
        <div
            className={classNames(
                classes["chat_contacts_list"],
                classes["chat_contacts_list_active"]
            )}
        >
            {/* <div className={classes["chat_search_block"]}>
                <div className={classes["chat_search_input"]}>
                    <div className={classes["image"]}>
                        <Icon id="icon-search" className="icon-search" />
                    </div>
                    <input
                        type="text"
                        placeholder="Search or start a new chat"
                        className={classes["chat_search"]}
                    />
                    <div
                        className={classNames(
                            classes["image"],
                            "display-none",
                            classes["chat_search_close_button"]
                        )}
                    >
                        <Icon id="icon-x" className="icon-x" />
                    </div>
                </div>
            </div> */}
            <div className={classes["chat_user_lists"]}>
                <div className={classes["chat_chats_block"]}>
                    <h4>Online</h4>
                    <ul className={classes["chat_list"]}>
                        {videoChat &&
                            videoChat.map((chat) => {
                                return (
                                    <li
                                        key={chat.chatId}
                                        className={classNames(
                                            classes[`chat_status_${chat.chatStatus}`],
                                            "chat-js",
                                            chatId && chat.chatId == chatId ? classes.active : ""
                                        )}
                                        onClick={() => showActiveChat(chat)}
                                    >
                                        {chat.photo ? (
                                            <div className={classNames(classes["avatar"], "avatar")}>
                                                <img src={chat.photo} alt="" />
                                            </div>
                                        ) : (
                                            <div className={classNames(classes["avatar"], "avatar")}>
                                                {`${chat.userName?.charAt(0)}`}
                                            </div>
                                        )}
                                        <div className={classes["name"]}>
                                            <p>{chat.userName}</p>
                                            {/* <span>{chat.latestMessage.text}</span> */}
                                        </div>
                                        <div className={classes["chat_info"]}>
                                            {/* <div className={classes["chat_time"]}>{formatDate(chat.latestMessage.date)}</div> */}
                                            {unreadMessages[chat.chatId] && (
                                                <div
                                                    key={chat.chatId}
                                                    className={classNames(classes["chat_messages_count"])}
                                                >
                                                    {unreadMessages[chat.chatId]}
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default VideoChatList;
