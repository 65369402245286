import React, { useEffect, useState } from "react";
import PageWrap from "../../app/components/PageWrap";

import classes from "./style/comparedDonorsPage.module.scss";
import ComparedCard from "./components/ComparedCard";
import MainChars from "./components/MainChars";
import GeneralChars from "./components/GeneralChars";
import { isMobile } from "react-device-detect";
import MainCharsMobile from "./components/MainCharsMobile";
import GeneralCharsMobile from "./components/GeneralCharsMobile";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ComparedDonorsPage = () => {
    const navigate = useNavigate()
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const saved = JSON.parse(localStorage.getItem("compared"));
    const [compare, setCompare] = useState([]);
    let mainCharsData1, mainCharsData2, generalCharsData1, generalCharsData2, mainCharsMobile, generalCharsMobile;

    const user = useSelector((state) => state.user.user);

    useEffect(() => {
        if(!userInfo || (user && !user?.isLoggedIn)){
            navigate('/login')
        }
    }, [user, userInfo]);

    useEffect(() => {
        if (saved) {
            saved.forEach((ele) => {
                fetchDonor(ele);
            });
        }
    }, []);

    const fetchDonor = async (el) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                    "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
                },
            };
            const reesponse = await axios.get(
                `https://api.surrogacy-platform.com/api/donors/${el}?type=user`,
                config
            );
            setCompare((prevCompare) => [...prevCompare, reesponse.data]);
        } catch (error) {
            console.error("Error loading:", error);
        }
    };

    if (compare.length > 1) {
        mainCharsData1 = [
            { icon: "eye", label: "Eye color", value: compare[0].eyeColor },
            {
                icon: "hair",
                label: "Hair color",
                value: compare[0].hairColor,
            },
            { icon: "height", label: "Height", value: compare[0].height },
            { icon: "body", label: "Body type", value: compare[0].bodyType },
            {
                icon: "baby-stroller",
                label: "Children",
                value: compare[0].children,
            },
            {
                icon: "rings",
                label: "Marital Status",
                value: compare[0].maritalStatus,
            },
        ];

        mainCharsData2 = [
            { icon: "eye", label: "Eye color", value: compare[1].eyeColor },
            {
                icon: "hair",
                label: "Hair color",
                value: compare[1].hairColor,
            },
            { icon: "height", label: "Height", value: compare[1].height },
            { icon: "body", label: "Body type", value: compare[1].bodyType },
            {
                icon: "baby-stroller",
                label: "Children",
                value: compare[1].children,
            },
            {
                icon: "rings",
                label: "Marital Status",
                value: compare[1].maritalStatus,
            },
        ];

        generalCharsData1 = [
            { label: "Level of Education", value: compare[0].education },
            { label: "Occupation", value: compare[0].occupation },
            { label: "Drinking", value: compare[0].drinking },
            { label: "Smoking", value: compare[0].smoking },
            { label: "Level of English", value: compare[0].levelOfEnglish },
            { label: "Other languages", value: compare[0].otherLanguages },
            { label: "Lives in", value: compare[0].country },
        ];

        generalCharsData2 = [
            { label: "Level of Education", value: compare[1].education },
            { label: "Occupation", value: compare[1].occupation },
            { label: "Drinking", value: compare[1].drinking },
            { label: "Smoking", value: compare[1].smoking },
            { label: "Level of English", value: compare[1].levelOfEnglish },
            { label: "Other languages", value: compare[1].otherLanguages },
            { label: "Lives in", value: compare[1].country },
        ];

        mainCharsMobile = [
            { icon: "eye", label: "Eye color", value0: compare[0].eyeColor, value1: compare[1].eyeColor },
            {
                icon: "hair",
                label: "Hair color",
                value0: compare[0].hairColor,
                value1: compare[1].hairColor,
            },
            { icon: "height", label: "Height", value0: compare[0].height, value1: compare[1].height },
            { icon: "body", label: "Body type", value0: compare[0].bodyType, value1: compare[1].bodyType },
            {
                icon: "baby-stroller",
                label: "Children",
                value0: compare[0].children,
                value1: compare[1].children,
            },
            {
                icon: "rings",
                label: "Marital Status",
                value0: compare[0].maritalStatus,
                value1: compare[1].maritalStatus,
            },
        ];

        generalCharsMobile = [
            { label: "Level of Education", value0: compare[0].education, value1: compare[1].education },
            { label: "Occupation", value0: compare[0].occupation, value1: compare[1].occupation },
            { label: "Drinking", value0: compare[0].drinking, value1: compare[1].drinking },
            { label: "Smoking", value0: compare[0].smoking, value1: compare[1].smoking },
            { label: "Level of English", value0: compare[0].levelOfEnglish, value1: compare[1].levelOfEnglish },
            { label: "Other languages", value0: compare[0].otherLanguages, value1: compare[1].otherLanguages },
            { label: "Lives in", value0: compare[0].country, value1: compare[1].country },
        ]
    }

    return (
        <PageWrap>
            <section className={classes["compare_2"]}>
                <div className="container">
                    {
                        !compare.length || compare.length == 1 ? (
                            <div className={classes["empty"]}>
                                <p>This comparison list is currently empty.</p>
                                <p>Add a donor to the comparison by clicking <Link to="/donors">here</Link>.</p>
                            </div>
                        ) : (
                            <>
                                <div className={classes["compare_2_cards_main"]}>
                                    {compare.length >= 2 && (
                                        <>
                                            <ComparedCard lady={compare[0]} />
                                            <ComparedCard lady={compare[1]} />
                                        </>
                                    )}
                                </div>
                                {isMobile ? (
                                    <>
                                        <MainCharsMobile mainCharsMobile={mainCharsMobile} />
                                        <GeneralCharsMobile generalCharsMobile={generalCharsMobile} />
                                    </>
                                ) : null}
                                {compare.length >= 2 ? (
                                    <>
                                        <MainChars
                                            mainCharsData1={mainCharsData1}
                                            mainCharsData2={mainCharsData2}
                                        />
                                        <GeneralChars
                                            generalCharsData1={generalCharsData1}
                                            generalCharsData2={generalCharsData2}
                                        />
                                    </>
                                ) : null}
                            </>
                        )
                    }
                </div>
            </section>
        </PageWrap>
    );
};

export default ComparedDonorsPage;
