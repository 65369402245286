import React, {useEffect, useState} from 'react';

import { Link, useNavigate } from "react-router-dom";
import {isMobile} from 'react-device-detect';

import Button from "../../ui/Button";
import Icon from "../../ui/Icon";

import classNames from "classnames";
import classes from './style/topMenu.module.scss';
import {useDispatch, useSelector} from "react-redux";
import { logoutRequest } from '../../actions/authActions';
import { getClientBalanceRequest } from '../../actions/userActions';
import { sendToDataLayer } from '../../utils/dataLayer';

const TopMenu = ({fullWidth, showMenu, toggleMenuShow, inChat}) => {

    const [showAccountMenu, setShowAccountMenu] = useState(false);
    const [showCoinsMenu, setShowCoinsMenu] = useState(false);
    const data = useSelector((state) => state.user);
    const userInfo = useSelector((state) => state.user.user);
    const hideMenu = useSelector((state) => state.globalState.hideMenu);
    const unreaded = useSelector((state) => state.user.unreaded);
    const userBalance = useSelector((state) => state.user.balance);

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if(showCoinsMenu){
            dispatch(getClientBalanceRequest())
        }
    }, [showCoinsMenu]);
    
    let mobileMenuList = [
        {
            title: "Donors",
            icon: "icon-menu-donors",
            link: "/donors"
        },
        {
            title: "Chat",
            icon: "icon-menu-chat",
            link: userInfo?.isLoggedIn ? "/textchat" : "/login",
            count: unreaded?.textChats
        },
        {
            title: "VideoChat",
            icon: "icon-menu-video",
            link: userInfo?.isLoggedIn ? "/videochat" : "/login"
        },
        {
            title: "Email",
            icon: "icon-email",
            link: userInfo?.isLoggedIn ? "/mailbox" : "/login",
            count: unreaded?.emails
        },
        {
            title: "Settings",
            icon: "icon-settings",
            link: userInfo?.isLoggedIn ? "/settings" : "/login"
        },
    ]

    const closeDropdowns = () => {
        if(showCoinsMenu) {
            setShowCoinsMenu(false)
        }
        if(showAccountMenu) {
            setShowAccountMenu(false)
        }
    }

    const onShowAccountMenu = () => {
        setShowAccountMenu(!showAccountMenu)
        if(showCoinsMenu) {
            setShowCoinsMenu(false)
        }
    }
    
    const onShowCoinsBalance = () => {
        sendToDataLayer('Show_balance', 'client', 'Show_balance', 'balance', userBalance?.userCoins)         
          
        setShowCoinsMenu(!showCoinsMenu)
        if(showAccountMenu) {
            setShowAccountMenu(false)
        }
    }

    const onClickTopUp = () => {
        navigate('/coins')
    }


    // const changeLang = () => {
    //     console.log('changeLang');
    // }

    const successLogout = (url) => {
        window.location.href = url
        sendToDataLayer('Log_out', 'client', 'Log_out', 'user', 1);
    }

    const onLogOut = async (data) => {
        try {
            await dispatch(logoutRequest({
                onSuccessRedirect: (url) => successLogout(url),
            }));
        } catch (error){
            console.error('error', error);
        }
    };

    return (
        <header className={classNames(inChat && classes['disables'], fullWidth && classes['full'])}>
            <div className={classNames("container", classes["container"])}>
            {
                isMobile && !hideMenu
                ?
                    <div className={classNames(classes["mobile_header_menu"], "mobile_header_menu")}>
                        <ul className={classes["mobile_header_menu_buttons"]}>
                        {mobileMenuList.map((item, index) => (
                            <li key={index}>
                                <Link to={item.link}>
                                    <div className={classes["image"]}>
                                        <Icon id={item.icon} className={item.icon} />
                                    </div>
                                    <span>
                                        {item.title}
                                    </span>
                                    {
                                        item.count > 0 && (
                                            <div className={classes["badge"]}>
                                                {item.count}
                                            </div>
                                        )
                                    }
                                </Link>
                            </li>
                        ))}
                        </ul>
                    </div>
                : null
            }
                <nav className={classNames(classes["nav_bar"], "nav_bar")}>
                    <div className={classNames(classes["image"], classes["burger-menu"])} onClick={showMenu}>
                        <Icon id="icon-burger" className="icon-burger" />
                    </div>
                    <div className={classes["nav_center_buttons"]}>
                        <ul>
                            <li>
                                <Link to="/about-us">About Us</Link>
                            </li>
                            <li>
                                <Link to="/program-stages">Program Stages</Link>
                            </li>
                            <li>
                                <Link to="/packages">Packages</Link>
                            </li>
                            {
                                (userInfo && userInfo?.isLoggedIn) && (
                                    <li>
                                        <Link to="/coins">Coins</Link>
                                    </li>
                                )
                            }
                            <li>
                                <Link to="/blog">Blog</Link>
                            </li>
                            <li>
                                <Link to="/contact-us">Contact Us</Link>
                            </li>
                        </ul>
                        {
                            isMobile
                            ?
                                <Link to="/" className={classes["mobile_logo"]}>
                                    <img src="/cdn/images/logo-big.svg" alt="" />
                                </Link>
                            : null
                        }
                    </div>
                    <div className={classes["nav_right_buttons"]}>
                        {/* <div className={classes["nav_right_buttons_lang_wrapper"]}>
                            <select className={classes["nav_right_buttons_lang"]} value="EN" onChange={changeLang}>
                                <option value="EN">EN</option>
                                <option value="UA">UA</option>
                            </select>
                            <div className={classes["image"]}>
                                <Icon id="icon-chevron-down-small" className="icon-chevron-down-small" />
                            </div>
                        </div> */}
                        {
                            !userInfo?.isLoggedIn ? (
                                <Link to="/login" className={classNames(classes["nav_right_buttons_login"], classes["account_menu_button"], classes['login-btn'])}>
                                    Sign In
                                </Link>
                            ) : (
                                <>
                                    <div className={classes['coins_block']} onClick={onShowCoinsBalance}>
                                        <img src="/cdn/images/coin_client.png" alt="icon coin" />
                                    </div>
                                    {
                                        userInfo.userAvatar ? (
                                            <div to="#" className={classNames("avatar", classes["account_menu_button"])} onClick={onShowAccountMenu}>
                                                <img src={userInfo.userAvatar} alt="" />
                                            </div>
                                        ) : (
                                            <div className={classNames(classes["account_menu_button"], classes["avatar"], "avatar")} onClick={onShowAccountMenu}>
                                                {
                                                    userInfo?.firstName ? (
                                                        `${userInfo?.firstName.charAt(0)}${userInfo?.lastName.charAt(0)}`
                                                    ) : null
                                                }
                                            </div>
                                        )
                                    }
                                    
                                    {
                                        showAccountMenu && (
                                            <ul className={classNames(classes["account_menu"])}>
                                                <li className={classes["link"]}>
                                                    <Link to="/profile" onClick={onShowAccountMenu}>
                                                        <div className={classes["image"]}>
                                                            <Icon id="icon-user" className="icon-user" />
                                                        </div>
                                                        <span>Profile</span>
                                                    </Link>
                                                </li>
                                                <li className={classes["link"]}>
                                                    <Link to="/saved-donors" onClick={onShowAccountMenu}>
                                                        <div className={classes["image"]}>
                                                            <Icon id="icon-saved" className="icon-saved" />
                                                        </div>
                                                        <span>Saved</span>
                                                    </Link>
                                                </li>
                                                <li className={classes["link"]}>
                                                    <Link to="/favourite-donors" onClick={onShowAccountMenu}>
                                                        <div className={classes["image"]}>
                                                            <Icon id="icon-liked" className="icon-liked" />
                                                        </div>
                                                        <span>Liked</span>
                                                    </Link>
                                                </li>
                                                <li className={classes["link"]}>
                                                    <Link to="/compared-donors" onClick={onShowAccountMenu}>
                                                        <div className={classes["image"]}>
                                                            <Icon id="icon-scales" className="icon-scales" />
                                                        </div>
                                                        <span>Compare</span>
                                                    </Link>
                                                </li>
                                                <li className={classes["link"]} onClick={onLogOut}>
                                                    <div className={classes['link-block']}>
                                                        <div className={classes["image"]}>
                                                            <Icon id="icon-power" className="icon-power" />
                                                        </div>
                                                        <span>Log Out</span>
                                                    </div>
                                                </li>
                                                {/* <li className="link">
                                                    <Link to="calendar.html">
                                                        <div className={classes["image"]}>
                                                            <Icon id="icon-calendar" className="icon-calendar" />
                                                        </div>
                                                        <span>Calendar</span>
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        )
                                    }
                                
                                </>
                            )
                        }
                        
                    </div>
                </nav>
                {
                    (showCoinsMenu && userBalance) && (
                        <div className={classes['coins_balance']}>
                            <div className={classes['coins_balance__header']}>
                                <p>Your  Balance </p>
                                <span>{userBalance.userCoins ? userBalance.userCoins : 0}</span>
                            </div>
                            <div className={classNames(classes['item'], classes['textchat'])}>
                                <div className={classes['info']}>
                                    <div className={classes['icon-block']}>
                                        <img src="/cdn/images/coin_client.png" alt="" />
                                        <Icon id="icon-menu-chat" className="icon-menu-chat" />
                                    </div>
                                    <div className={classes['name']}>
                                        <p>Chat Messages</p>
                                    </div>
                                </div>
                                <span className={classes['count']}>{Math.trunc(userBalance ? userBalance.textChats : 0)}</span>
                            </div>
                            <div className={classes['separator']}>or</div>
                            <div className={classNames(classes['item'], classes['videochat'])}>
                                <div className={classes['info']}>
                                    <div className={classes['icon-block']}>
                                        <img src="/cdn/images/coin_client.png" alt="" />
                                        <Icon id="icon-menu-video" className="icon-menu-video" />
                                    </div>
                                    <div className={classes['name']}>
                                        <p>Video Chats min</p>
                                    </div>
                                </div>
                                <span className={classes['count']}>{userBalance ? userBalance.videoChats : 0}</span>
                            </div>
                            <div className={classes['separator']}>or</div>
                            <div className={classNames(classes['item'], classes['emails'])}>
                                <div className={classes['info']}>
                                    <div className={classes['icon-block']}>
                                        <img src="/cdn/images/coin_client.png" alt="" />
                                        <Icon id="icon-email" className="icon-email" />
                                    </div>
                                    <div className={classes['name']}>
                                        <p>Emails</p>
                                    </div>
                                </div>
                                <span className={classes['count']}>{userBalance ? userBalance.emails : 0}</span>
                            </div>
                            <div className={classes['button-block']}>
                                <Button onClick={onClickTopUp}>Top up your balance</Button>
                            </div>
                        </div>
                    )
                }
                {
                    isMobile 
                    ?
                        <div className={classNames(classes["mobile_menu"], !toggleMenuShow ? 'menu-hide' : '')}>
                            <div className={classes["close_button"]} onClick={showMenu}>
                                <Icon id="icon-x" className="icon-x" />
                            </div>
                            <ul>
                                <li>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/program-stages">Program Stages</Link>
                                </li>
                                <li>
                                    <Link to="/packages">Packages</Link>
                                </li>
                                {
                                    (userInfo && userInfo?.isLoggedIn) && (
                                        <li>
                                            <Link to="/coins">Coins</Link>
                                        </li>
                                    )
                                }
                                <li>
                                    <Link to="/blog">Blog</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">Contact Us</Link>
                                </li>
                            </ul>
                            {/* <div className={classNames(classes["nav_right_buttons_lang_wrapper"], classes["mobile_lang"])}>
                                <select className={classes["nav_right_buttons_lang"]} value="EN" onChange={changeLang}>
                                    <option value="EN">EN</option>
                                    <option value="UA">UA</option>
                                </select>
                                <div className={classes["image"]}>
                                    <Icon id="icon-chevron-down-small" className="icon-chevron-down-small" />
                                </div>
                            </div> */}
                        </div>
                    : null
                }
            </div>
            {
                (showAccountMenu || showCoinsMenu) && (
                    <div className='overlay' onClick={closeDropdowns} />
                )
            }
        </header>
    );
}

export default TopMenu;
