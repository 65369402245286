import { useDispatch, useSelector } from 'react-redux';
// import { loginRequest } from '../actions/authActions';
import Button from '../../ui/Button';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import classes from "./style/donorRequestForm.module.scss";
import Icon from '../../ui/Icon';
import Input from '../../ui/Input';
import classNames from 'classnames';
import { useState } from 'react';
import { donorApplicationRequest } from '../../actions/donorActions';
import { sendToDataLayer } from '../../utils/dataLayer';

const DonorRequestForm = ({type, requestSuccess}) => {
    const dispatch = useDispatch();
    const [successRequest, setSuccessRequest] = useState(false);
    const [globalError, setGlobalError] = useState('');

    const schema = yup
        .object({
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            email: yup
                .string()
                .email()
                .required(),
            phoneNumber: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })
    
    const onSubmit = async (data) => {
        try {
            const requestData = {
                ...data,
                donorType: type
            }
            dispatch(donorApplicationRequest(requestData, (response, error) => {
                if (response) {
                    if(type == 'eggs') {
                        sendToDataLayer('First_form_Become_egg_donor', 'donor', 'First_form_Become_egg_donor', 'egg', 1)
                    }
                    if(type == 'surrogacy') {
                        sendToDataLayer('First_form_Become_SM', 'donor', 'First_form_Become_SM', 'sm', 1)
                    }
                    if(type == 'sperm') {
                        sendToDataLayer('First_form_Become_sperm', 'donor', 'First_form_Become_sperm', 'sperm', 1)
                    }
                    setSuccessRequest(true)
                } else {
                    setGlobalError(error.data.message)
                    console.error("Failed donor request:", error.data.message);
                }
            }))
        } catch (error){
            console.error('Error', error);
        }
    };
    
    return (
        <div className={classes['request-form']}>
            {
                successRequest ? (
                    <p className={classes['success-text']}>Your application has been received!<br />
                    Further instructions have been sent to your email.<br />
                    Thank you for your interest!</p>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={classNames(classes['input-row'], 'row')}>
                            <Input
                                type="text"
                                name="firstName"
                                useFormEl={register('firstName')}
                                placeholderNormal="First Name"
                                withError={errors.firstName && true}
                                errorText={errors?.firstName?.type == 'firstName' ? 'Invalid First Name format' : 'Please, enter your First Name'}
                                placeHolder="Your First Name"
                            />
                            <Input
                                type="text"
                                name="lastName"
                                useFormEl={register('lastName')}
                                placeholderNormal="Last Name"
                                withError={errors.lastName && true}
                                errorText={errors?.lastName?.type == 'lastName' ? 'Invalid Last Name format' : 'Please, enter your Last Name'}
                                placeHolder="Your Last Name"
                            />
                        </div>
                        <Input
                            type="email"
                            name="email"
                            useFormEl={register('email')}
                            placeholderNormal="E-mail"
                            withError={errors.email && true}
                            errorText={errors?.email?.type == 'email' ? 'Invalid Email format' : 'Please, enter your Email'}
                            placeHolder="Your Email"
                        />
                        <Input
                            type="text"
                            name="phoneNumber"
                            useFormEl={register('phoneNumber')}
                            placeholderNormal="Enter Your Phone"
                            addProps={{ autoComplete: 'off' }}
                            withError={errors.phoneNumber && true}
                            errorText="Please, enter Your Phone"
                            placeHolder="Your Phone"
                        />
                        {
                            globalError && (
                                <div className={classes["error-block"]}>
                                    <Icon id="icon-alert" />
                                    <p>{globalError}</p>
                                </div>

                            )
                        }
                        <Button type="submit" addClasses={["button"]} onClick={handleSubmit(onSubmit)}>
                            Send
                        </Button>
                    </form>
                )
            }
        </div>
    );
}

export default DonorRequestForm;
