import { useContext, useEffect } from 'react';
import useInterval from './useInterval';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOnlineDonorsRequest } from '../actions/videoChatActions';
import { SocketContext } from '../context/socket';
import { setSocketConnection } from '../actions/globalActions';
import { getUserUnreadedRequest } from '../actions/userActions';

export default function useEventObserver() {
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const socketConnected = useSelector((state) => state.globalState.socketConnected)
    const user = useSelector((state) => state.user.user)

    useEffect(() => {
        if(user && !socketConnected){
            dispatch(fetchOnlineDonorsRequest());
            socket.emit('setup', { id: user.id });
            socket.on('connected', () => {
                dispatch(setSocketConnection(true))
                console.log('Socket successfully conncted');
            });
            socket.emit('global-client-notifications', {id: user.id})
        }
        if(socketConnected){
            socket.on('global-notifications', (res) => {
                dispatch(getUserUnreadedRequest(res))
            });
        }
    }, [user, socketConnected]);

    useInterval(() => {
        if (user && document.visibilityState === 'visible' && userInfo) {
            dispatch(fetchOnlineDonorsRequest());
        }
    }, 60000)

    useInterval(() => {
        if (user && document.visibilityState === 'visible' && userInfo) {
            socket.emit('setup', { id: user.id });
            socket.on('connected', () => {
                dispatch(setSocketConnection(true))
                console.log('Socket successfully conncted');
            });
            socket.emit('global-client-notifications', {id: user.id})
        }
    }, 300000)
}