export const sendToDataLayer = (event, category, action, label, value) => {
    window.dataLayer.push({
        event,
        eventProps: {
            category,
            action,
            label,
            value
        }
    });
};
