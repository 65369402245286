import React, { useState } from "react";
import { isMobile } from "react-device-detect";

import Icon from "../../ui/Icon";

import classes from "./style/tabs.module.scss";
import classNames from "classnames";

const Tabs = ({activeTab, onChangeTab, tabsList, hideInMobile = true}) => {

    const [showMobileTabs, setShowMobileTabs] = useState(false);
    

    const renderTabs = () => {
        return tabsList.map((tab, index) => (
            tab.show && (
                <li key={index} className={classNames(classes["tab"], tab.label === activeTab.label ? classes["active"] : "")} onClick={() => changeTab(tab)}>
                    {tab.title}
                </li>
            )
        ));
    };

    const onShowMobTabs = () => {
        setShowMobileTabs(!showMobileTabs)
    }

    const changeTab = (tab) => {
        onChangeTab(tab)
        setShowMobileTabs(!showMobileTabs)
    }

    return (
        <section className={classes["tabs_section"]}>
            {isMobile && hideInMobile ? (
                <div className={classes["tabs_mobile"]}>
                    <div className={classes["tabs_mobile_dropdown"]} onClick={onShowMobTabs}>
                        <span>{activeTab.title}</span>
                        <div className={classes["image"]}>
                            <Icon className="icon-arrow-down" id="icon-arrow-down" />
                        </div>
                    </div>
                    <ul className={classNames(classes["tabs_mobile_dropdown_list"], showMobileTabs ? '' : "display-none")}>
                        {renderTabs()}
                    </ul>
                </div>
            ) : (
                <ul className={classes["tabs"]}>
                    {renderTabs()}
                </ul>
            )}
        </section>
    );
}

export default Tabs;
