import { put, takeLatest, call } from 'redux-saga/effects';
import {
  fetchChatsSuccess,
  fetchChatsFailure,
  fetchMessagesSuccess,
  fetchMessagesFailure,
  fetchNotificationsSuccess,
  fetchNotificationsFailure,
  updateNotificationSuccess,
  updateNotificationFailure,
} from '../actions/textChatActions'; // Import your chat-related actions
import { FETCH_CHATS_REQUEST, FETCH_MESSAGES_REQUEST, FETCH_NOTIFICATIONS_REQUEST, UPDATE_NOTIFICATION_REQUEST } from '../constants/actionTypes';

import api from '../api/api';

function* fetchChats(action) {
  try {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const headers = {
      Authorization: `Bearer ${user.token}`,
    };
    const response = yield call(api.get, '/conversation/chats-by-donor-and-client', action.payload, {headers});
    yield put(fetchChatsSuccess(response.data));
  } catch (error) {
    yield put(fetchChatsFailure(error));
  }
}

function* fetchMessagesSaga(action) {
    try {
        const { chatId, page, limit } = action;
        const user = JSON.parse(localStorage.getItem("userInfo"));
        const headers = {
            Authorization: `Bearer ${user.token}`,
        };
      const response = yield call(api.get, `/messages/conversation-mes/${chatId}?page=${page}&limit=${limit}`, {headers});
      const messages = response.data.messages;
      const totalMessagesCount = response.data.totalMessagesCount
      yield put(fetchMessagesSuccess(chatId, messages, totalMessagesCount));
    } catch (error) {
      yield put(fetchMessagesFailure(error.message));
    }
}

function* fetchNotificationsSaga() {
  try {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const headers = {
        Authorization: `Bearer ${user.token}`,
        "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
    };
    const response = yield call(api.get, 'https://api.surrogacy-platform.com/api/users/notifications', { headers });

    yield put(fetchNotificationsSuccess(response.data));
  } catch (error) {
    yield put(fetchNotificationsFailure(error.message));
  }
}

function* updateNotificationSaga(action) {
  try {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const id = action.payload;
    const response = yield call(api.post, `/users/update-notification?chatId=${id}&plus=false&userId=${user.userId}`, { headers });

    yield put(updateNotificationSuccess(response.data));
  } catch (error) {
    yield put(updateNotificationFailure(error.message));
  }
}

function* textChatSaga() {
  yield takeLatest(FETCH_CHATS_REQUEST, fetchChats);
  yield takeLatest(FETCH_MESSAGES_REQUEST, fetchMessagesSaga);
  yield takeLatest(FETCH_NOTIFICATIONS_REQUEST, fetchNotificationsSaga);
  yield takeLatest(UPDATE_NOTIFICATION_REQUEST, updateNotificationSaga);

}

export default textChatSaga;
