import React, { useEffect, useMemo, useState } from "react";

import Icon from "../../../ui/Icon";
import Button from "../../../ui/Button";

import classes from "../style/infoSection.module.scss";
import classNames from "classnames";
import { ChatState } from "../../../context/chatProvider";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../../components/Modal";
import VideochatButton from "../../../components/VideochatButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchPopup } from "../../../actions/modalActions";
import { sendConversationRequest } from "../../../actions/globalActions";
import { sendToDataLayer } from "../../../utils/dataLayer";

const InfoSection = ({ donor, onShowComposeLetter, onShowLowCoinsPopup }) => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [mainPhoto, setMainPhoto] = useState(donor.userAvatar);
    const [primaryDonorPhotos, setPrimaryDonorPhotos] = useState([]);
    const { donorParams } = useParams();

    const { setSelectedChat, chats, setChats, setTargetRoom } = ChatState();

    const userInfo = useSelector((state) => state.user.user);
    const onlineDonors = useSelector((state) => state.videoChat.onlineDonors);
    const donorPhotos = useSelector((state) => state.donors.donorPhotos);

    useEffect(() => {
        if (donorPhotos && donorPhotos[donor.id]?.length) {
            const mergedArray = [].concat(...Object.values(donorPhotos));
            const filteredArray = mergedArray.filter(
                (obj) => obj.album === "primary" && !obj.isAvatar
            );
            setPrimaryDonorPhotos(filteredArray);
        }
    }, [donorPhotos]);

    const accessChat = async (donorId, path, hasVideo) => {
        sendToDataLayer('Button_invite_chat', 'client', 'Button_invite_chat', 'user', donor.id)          
        if (!userInfo || !userInfo.isLoggedIn || !user) {
            navigate("/login");
        } else {
            if (!userInfo.wallet) {
                onShowLowCoinsPopup();
                return;
            }
            let balance = userInfo?.wallet?.amount;
    
            if (hasVideo && balance < 100) {
                onShowLowCoinsPopup();
                return;
            }
    
            if (!hasVideo && balance < 20) {
                onShowLowCoinsPopup();
                return;
            }
            
            const data = {
                senderClientId: user.userId,
                donorId: donorId,
                type: "CHAT",
                hasVideo: hasVideo,
            };
            dispatch(
                sendConversationRequest(data, (response, error) => {
                    if (response) {
                        if (!chats.find((c) => c.id === response.item.id))
                            setChats([response.item, ...chats]);
                        setSelectedChat(response.item.id);
                        navigate(`${path}${response.item.id}`);
                    } else {
                        console.error("ErrorError:", error);
                    }
                })
            );
        }
    };

    const changeMainPhoto = (primaryDonorPhotos, index) => {
        setMainPhoto(primaryDonorPhotos[index].url);
    };

    const donorInfoLeft = [
        { icon: "eye", label: "Eye color", value: donor.eyeColor },
        { icon: "hair", label: "Hair color", value: donor.hairColor },
        { icon: "height", label: "Height", value: `${donor.height} cm` },
        { icon: "body", label: "Body type", value: donor.bodyType },
        { icon: "baby-stroller",label: "Children",value: donor.children,},
        { icon: "rings",label: "Marital Status",value: donor.maritalStatus,},
    ];

    const donorInfoRight = [
        { label: "Level of Education", value: donor.education },
        { label: "Occupation", value: donor.occupation },
        { label: "Drinking", value: donor.drinking },
        { label: "Smoking", value: donor.smoking },
        { label: "Level of English", value: donor.levelOfEnglish },
        { label: "Other languages", value: donor.otherLanguages },
        { label: "Lives in", value: donor.country },
    ];

    const openModal = () => {
        sendToDataLayer('Button_order_donor', 'client', 'Button_order_donor', 'user', donor.id)          
        dispatch(
            fetchPopup({
                popupType: "ProgramsModal",
                props: {
                    className: "programs",
                    donorId: donor.id,
                    donorName: donor.firstName
                },
            })
        );
    };

    function formatDate(dateString) {
        if (!dateString) return ""; 

        const date = new Date(dateString);
    
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        return `${day}.${month}.${year}`;
    }

    const label = useMemo(() => {
        if (donor && donor.label) {
            if (donor.label.toLowerCase() == ("new")) {
                return <div className={classes["card_badge_new"]}>NEW</div>;
            } else if (donor.label.toLowerCase() == ("top")) {
                return <div className={classes["card_badge_top"]}>TOP</div>;
            }
        } else {
            return null;
        }
    }, [donor]);

    return (
        <section className={classes["personal"]}>
            <div className={classNames(classes["personal_row"])}>
                <div className={classes["personal_gallery"]}>
                    <div>
                        <div>
                            <div className={classes["personal_main_photo"]}>
                                {donor.label ? (
                                    <div className={classes["label"]}>
                                        {label}
                                    </div>
                                ) : null}
                                <div className={classes["personal_carousel_image_container"]}>
                                    <div>
                                        <img
                                            className={classes["personal_main_photo_image"]}
                                            src={mainPhoto}
                                            alt="..."
                                        />
                                    </div>
                                </div>
                            </div>
                            {user && (
                                <div className={classes["personal_carousel_wrapper"]}>
                                    <div className={classes["personal_carousel"]}>
                                        {primaryDonorPhotos.slice(0, 4).map((image, index) => (
                                            <div
                                                key={index}
                                                onClick={() =>
                                                    changeMainPhoto(primaryDonorPhotos, index)
                                                }
                                            >
                                                <div>
                                                    <div>
                                                        <img
                                                            className={classes["personal_carousel_image"]}
                                                            src={image.url}
                                                            alt="..."
                                                            loading="lazy"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={classes["personal_info"]}>
                    <div
                        className={classNames(
                            classes["title"],
                            onlineDonors &&
                            onlineDonors.find((item) => item.donorId == donor.id) &&
                            classes["online"]
                        )}
                    >
                        <p className={classes["name"]}>
                            {donor.firstName}
                            {donor.age ? (
                                <span className={classes["age"]}>, {donor.age}</span>
                            ) : (
                                <span className={classes["age"]}>, age</span>
                            )}
                        </p>
                    </div>
                    <div className={classes["location"]}>
                        <Icon className="icon-location" id="icon-location" />
                        <span className={classes["location_country"]}>{donor.country}</span>
                    </div>
                    <div className={classes["birthday"]}>
                        <img src="/cdn/images/bd-icon.png" alt="" />
                        <span>{formatDate(donor.dateOfBirth)}</span>
                    </div>
                    <div className={classes["data"]}>
                        <ul className={classes["main_info"]}>
                            {donorInfoLeft.map(
                                (item, index) =>
                                    item.value && (
                                        <li key={index}>
                                            <div>
                                                <img
                                                    src={`/cdn/images/donor-profile/${item.icon}.png`}
                                                    alt=""
                                                />
                                                <span>{item.label}</span>
                                            </div>
                                            <span>{item.value}</span>
                                        </li>
                                    )
                            )}
                        </ul>
                        <ul className={classes["additional_info"]}>
                            {donorInfoRight.map(
                                (item, index) =>
                                    item.value && (
                                        <li key={index}>
                                            <span>{item.label}</span>
                                            <span>{item.value}</span>
                                        </li>
                                    )
                            )}
                        </ul>
                    </div>
                    <div className={classes["personal_buttons"]}>
                        <div className={classes["invite"]}>
                            <Button
                                addClasses={["button", "capitalize"]}
                                onClick={() => accessChat(donor.id, "/textchat/", false)}
                            >
                                Invite Chats
                            </Button>
                            <Button
                                addClasses={[
                                    "with-icon",
                                    "button",
                                    "flat-rounded",
                                    "capitalize",
                                ]}
                                onClick={onShowComposeLetter}
                            >
                                <Icon className="icon-mail" id="icon-mail" />
                                Send Email
                            </Button>
                        </div>
                        <div className={classes["video"]}>
                            <VideochatButton type="profile" donorId={donor.id} donorName={donor.firstName} />
                            <Button
                                addClasses={[
                                    "with-icon",
                                    "button",
                                    "flat-rounded",
                                    "capitalize",
                                ]}
                                onClick={openModal}
                            >
                                <Icon className="icon-target" id="icon-target" />
                                Order Donor
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default InfoSection;
