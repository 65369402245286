import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useClickAway } from "@uidotdev/usehooks";
import { convertDatetime } from "../../../utils/commonutils";
import { addEmailLabelRequest, deleteConversationRequest, deleteEmailsLabelsRequest, fetchEmailsRequest, fetchStarRequest, getEmailsSentRequest, getEmailsStarredRequest, updateEmailNotificationRequest } from "../../../actions/emailActions";

import Icon from "../../../ui/Icon";

import classNames from "classnames";
import classes from "../style/mailboxLettersList.module.scss";
import { SocketContext } from "../../../context/socket";

const MailboxLettersList = ({
    emailMessages,
    onShowMenuMobile,
    showMenuMobile,
    isSent,
    isDeleted,
    isStarred,
    currentPage,
    notificationCount,
    onShowNotifyPopup
}) => {
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [unreadedMails, setUnreadedMails] = useState(null);
    const [starredEmails, setStarredEmails] = useState([]);
    const [showingLabelsList, setShowingLabelsList] = useState(false);
    const [emailsList, setEmailsList] = useState([]);
    const user = useSelector((state) => state.user.user);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);

    useEffect(() => {
        setEmailsList(emailMessages)
    }, [emailMessages]);

    useEffect(() => {
        if(isDeleted){
            setSelectedCheckboxes([])
        }
    }, [isDeleted]);

    const ref = useClickAway(() => {
        if(showingLabelsList){
            setShowingLabelsList(false)
        }
    });

    const handleNotification = useCallback((mes) => {
        dispatch(fetchEmailsRequest({page: currentPage, limit:10}));
    }, []);

    useEffect(() => {
        socket.on("notification", (mes) => handleNotification(mes));

        return () => {
            socket.off("notification");
        };
    }, [socket]);

    const toggleCheckbox = (mailId) => {
        setSelectedCheckboxes((prevSelected) => {
            if (prevSelected.includes(mailId)) {
                return prevSelected.filter((item) => item !== mailId);
            } else {
                return [...prevSelected, mailId];
            }
        });
    };

    const toggleSelectAll = () => {
        setSelectAll((prevSelectAll) => !prevSelectAll);
        setSelectedCheckboxes(() =>
            selectAll ? [] : emailMessages.map((mess) => mess.mailId)
        );
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if(searchQuery) {
            const filtered = Object.values(emailMessages).filter(
                (message) =>
                message.sender.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                message.subject.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setEmailsList(filtered)
        }
    }, [searchQuery, emailMessages]);

    const onUnreadAction = () => {
        if(selectedCheckboxes){
            setUnreadedMails(selectedCheckboxes)
        }
    };

    useEffect(() => {
        if(unreadedMails){
            dispatch(updateEmailNotificationRequest(unreadedMails));
            setSelectedCheckboxes([])
            dispatch(fetchEmailsRequest({page: currentPage, limit:10}));
        }
    }, [unreadedMails]);

    const deleteMessage = async () => {
            try {
              await dispatch(deleteConversationRequest(selectedCheckboxes, (response, error) => {
                if (response) {
                    if(isSent){
                        dispatch(getEmailsSentRequest({page: currentPage, limit:10}))
                    }else if(isStarred) {
                        dispatch(getEmailsStarredRequest({page: currentPage, limit:10}));
                    }else {
                        dispatch(fetchEmailsRequest({page: currentPage, limit:10}));
                    }
                }else {
                    console.error("Failed to delete email:", error);
                }
              }));
              
            } catch (error) {
              console.error('Delete message error', error);
            }
    };

    const showMail = (mailId) => {
        navigate(`/mailbox/readletter/${mailId}`);
        // if(notificationCount && notificationCount < 10){
        //     onShowNotifyPopup()
        // }else {
        // }
    };

    const handleMessageInFav = async (mailId) => {
            dispatch(
                fetchStarRequest(mailId, (response, error) => {
                if (response) {
                    setStarredEmails((prevFavs) => {
                        const isAlreadyFav = prevFavs.includes(mailId);
                        if (isAlreadyFav) {
                            dispatch(fetchEmailsRequest({page: currentPage, limit:10}));
                            return prevFavs.filter((id) => id !== mailId);
                        } else {
                            return [...prevFavs, mailId];

                        }
                    });
                } else {
                    console.error("Failed to send email:", error);
                }
            })
        );
    };

    const addLabel = (label) => {
        if(selectedCheckboxes){
            let data = {
                category: label,
                emailIds:selectedCheckboxes
            }
            dispatch(addEmailLabelRequest(data, (response, error) => {
                if (response) {
                    if(isSent){
                        dispatch(getEmailsSentRequest({page: currentPage, limit:10}))
                    }else if(isStarred) {
                        dispatch(getEmailsStarredRequest({page: currentPage, limit:10}));
                    }else {
                        dispatch(fetchEmailsRequest({page: currentPage, limit:10}));
                    }
                    setSelectedCheckboxes([])
                    setSelectAll(false)
                    setShowingLabelsList(false)
                } else {
                    console.error("Failed to send email:", error);
                }
            }))
        }
    };

    const removeLabels = () => {
        if(selectedCheckboxes){
            dispatch(deleteEmailsLabelsRequest(selectedCheckboxes, (response, error) => {
                if (response) {
                    if(isSent){
                        dispatch(getEmailsSentRequest({page: currentPage, limit:10}))
                    }else if(isStarred) {
                        dispatch(getEmailsStarredRequest({page: currentPage, limit:10}));
                    }else {
                        dispatch(fetchEmailsRequest({page: currentPage, limit:10}));
                    }
                    setSelectedCheckboxes([])
                    setSelectAll(false)
                    setShowingLabelsList(false)
                } else {
                    console.error("Failed to send email:", error);
                }
            }))
        }
    }

    const showTagMenu = () => {
        setShowingLabelsList(!showingLabelsList)
    }

    return (
        <div
            className={classNames(
                classes.email_main,
                showMenuMobile ? classes.overlay : ""
            )}
            onClick={showMenuMobile ? onShowMenuMobile : undefined}
        >
            <div className={classes.email_search}>
                <div
                    className={classNames(classes.image, classes.email_back_button)}
                    onClick={onShowMenuMobile}
                >
                    <Icon className="icon-burger" id="icon-burger" />
                </div>
                <Icon className="icon-search" id="icon-search" />
                <input
                    type="text"
                    placeholder="Search email"
                    className={classes.search_email}
                    value={searchQuery}
                    onChange={handleSearch}
                />
                <div
                    className={classNames(
                        classes.close_button_search,
                        !searchQuery ? "display-none" : ""
                    )}
                    onClick={() => setSearchQuery("")}
                >
                    <Icon className="icon-x" id="icon-x" />
                </div>
            </div>
            <div className={classes.email_buttons_block}>
                {
                    !isDeleted && (
                        <div className="checkbox-row">
                            <input
                                type="checkbox"
                                id="all"
                                className={classNames(
                                    "checkbox_input",
                                    selectAll ? "checkbox_checked" : ""
                                )}
                                defaultValue="all"
                                checked={selectAll}
                                onChange={toggleSelectAll}
                            />
                            <label htmlFor="all">Select All</label>
                        </div>

                    )
                }
                <ul className={classes.email_buttons}>
                    {
                        !isDeleted && (
                            <>
                                <li className={classNames(showingLabelsList && classes['active'])} onClick={showTagMenu}>
                                    <Icon className={`icon-tag`} id={`icon-tag`} />
                                </li>
                                {
                                    showingLabelsList && (
                                        <div className={classes["dropdown-menu"]} ref={ref}>
                                            <div className={classes["dropdown-item"]} onClick={() => addLabel('Personal')}>
                                                <i className={classNames(classes["badge"], classes["personal"])}></i>
                                                <span>Personal</span>
                                            </div>
                                            <div className={classes["dropdown-item"]} onClick={() => addLabel('Company')}>
                                                <i className={classNames(classes["badge"], classes["company"])}></i>
                                                <span>Company</span>
                                            </div>
                                            <div className={classes["dropdown-item"]} onClick={() => addLabel('Important')}>
                                                <i className={classNames(classes["badge"], classes["important"])}></i>
                                                <span>Important</span>
                                            </div>
                                            <div className={classes["dropdown-item"]} onClick={() => addLabel('Private')}>
                                                <i className={classNames(classes["badge"], classes["private"])}></i>
                                                <span>Private</span>
                                            </div>
                                            <div className={classes["dropdown-item"]} onClick={removeLabels}>
                                                <i className={classNames(classes["badge"])}>
                                                    <Icon id="icon-close" />
                                                </i>
                                                <span>Delete labels</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                    <li onClick={onUnreadAction}>
                        <Icon className={`icon-message`} id={`icon-message`} />
                    </li>
                    {
                        !isDeleted && (
                            <li onClick={deleteMessage}>
                                <Icon className={`icon-bin`} id={`icon-bin`} />
                            </li>
                        )
                    }
                </ul>
            </div>
            <div className={classes.email_list}>
                {
                    emailsList.length ? (
                        emailsList.map((message, index) => 
                        <div
                            key={index}
                            className={classNames(
                                classes.email_message,
                                selectedCheckboxes.includes(message.mailId)
                                    ? classes.email_message_checked
                                    : "",
                                message.unreaded ? classes.unreaded : ""
                            )}
                        >
                            <div
                                className={classes.email_info}
                                onClick={() => showMail(message.mailId)}
                            >
                                {
                                    message.sender && message.sender.photo ? (
                                        <div className={classNames(classes["avatar"], "avatar")}>
                                            <img src={message.sender.photo} alt="" />
                                        </div>
                                    ) : (
                                        <div className={classNames(classes["avatar"], "avatar")}>
                                        {`${message.sender.name.charAt(0)}`}
                                    </div>
                                    )
                                }                                
                                <div className={classes.email_data}>
                                    <span className={classes.email_name}>
                                        {isSent ? (
                                            <>
                                                {`To ${message.sender.name}`}
                                            </>
                                        ) : (
                                            <>
                                                {`${message.sender.name}`}
                                            </>
                                        )}
                                    </span>
                                    <span className={classes.email_title}>{message.subject}</span>
                                </div>
                                <div className={classes.email_additional}>
                                    {message.withAttachment && (
                                        <Icon className="icon-paperclip" id="icon-paperclip" />
                                    )}
                                    <div
                                        className={classNames(
                                            classes[message.label?.toLowerCase()],
                                            classes["email_type"]
                                        )}
                                    />
                                    <span className={classes.email_datetime}>
                                        {convertDatetime(message.latestMessage.date, user?.timezoneTimestamp)}
                                    </span>
                                </div>
                            </div>
                            <div className={classes.email_content}>
                                {
                                    !isDeleted && (
                                        <div className="checkbox-row">
                                            <input
                                                id={message.mailId}
                                                type="checkbox"
                                                className={classNames(
                                                    "checkbox_input",
                                                    "checkbox_on_email",
                                                    selectedCheckboxes.includes(message.mailId)
                                                        ? "checkbox_checked"
                                                        : ""
                                                )}
                                                onChange={(e) => e.stopPropagation()}
                                                onClick={(e) => {
                                                    toggleCheckbox(message.mailId);
                                                    e.stopPropagation();
                                                }}
                                                checked={selectedCheckboxes.includes(message.mailId)}
                                            />
                                            <label htmlFor={message.mailId}></label>
                                        </div>
                                    )
                                }
                                {
                                    !isDeleted && (
                                        <div className={classes.email_star} onClick={() => handleMessageInFav(message.mailId)}>
                                            {message.isStarred || starredEmails.includes(message.mailId) ? (
                                                <Icon
                                                    className="icon-star-checked"
                                                    id="icon-star-checked"
                                                />
                                            ) : (
                                                <Icon
                                                    className="icon-starred"
                                                    id="icon-starred"
                                                />
                                            )}
                                        </div>
                                    )
                                }

                                {message.latestMessage&&(
                                    <p>{message.latestMessage.text}</p>
                                )}
                            </div>
                        </div>
                    )
                    ) : (
                        <div className={classes["empty_block"]} onClick={() => navigate('/donors')}>
                            <img src="/cdn/images/empty-chat.png" alt="" />
                            <p>Compose new mail to donor</p>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default MailboxLettersList;
