import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import axios from "axios";
import { ChatState } from "../../context/chatProvider";
import { selectDonorForEmail } from "../../actions/emailActions";

import Icon from "../../ui/Icon";
import Modal from "../Modal";

import classes from "./style/ladyCard.module.scss";
import classNames from "classnames";
import { sendConversationRequest } from "../../actions/globalActions";
import { SocketContext } from "../../context/socket";
import { setChatConnection } from "../../actions/videoChatActions";
import VideochatButton from "../VideochatButton";
import { fetchPopup } from "../../actions/modalActions";
import { sendToDataLayer } from "../../utils/dataLayer";

const LadyCard = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);

    const user = JSON.parse(localStorage.getItem("userInfo"));
    const compared = JSON.parse(localStorage.getItem("compared"));

    const [savedeDonors, setSavedeDonors] = useState([]);
    const [comparedDonors, setComparedDonors] = useState([]);
    const [favDonors, setFavDonors] = useState([]);
    const [showButtons, setShowButtons] = useState(false);
    
    const data = useSelector((state) => state.user);
    const userInfo = useSelector((state) => state.user.user);
    const onlineDonors = useSelector(state => state.videoChat.onlineDonors);

    useEffect(() => {
        if (props.lady.isSaved) {
            setSavedeDonors([...savedeDonors, props.lady.id]);
        }
        if (props.lady.isLiked) {
            setFavDonors([...savedeDonors, props.lady.id]);
        }
    }, []);

    const { setSelectedChat, chats, setChats } =
        ChatState();

    const label = useMemo(() => {
        if (props.lady && props.lady.label) {
            if (props.lady.label.toLowerCase() == ("new")) {
                return <div className={classes["card_badge_new"]}>NEW</div>;
            } else if (props.lady.label.toLowerCase() == ("top")) {
                return <div className={classes["card_badge_top"]}>TOP</div>;
            }
        } else {
            return null;
        }
    }, [props]);

    const addToSaved = async (ladyId) => {
        if (!user || (data.user && !data.user?.isLoggedIn)) {
            navigate("/login");
        }else {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                    "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
                },
            };
            const { data } = await axios.post(
                `https://api.surrogacy-platform.com/api/users/saveDonor`,
                {
                    donorId: ladyId,
                },
                config
            );
    
            const isAlreadySaved = savedeDonors.includes(ladyId);
    
            if (isAlreadySaved) {
                const updatedSavedDonors = savedeDonors.filter((id) => id !== ladyId);
                setSavedeDonors(updatedSavedDonors);
            } else {
                setSavedeDonors([...savedeDonors, ladyId]);
                sendToDataLayer('Save_donor', 'client', 'Save_donor', 'user', ladyId)
            }
        }
    };

    const emailHandler = (donor) => {
        if (!user || (data.user && !data.user?.isLoggedIn)) {
            navigate("/login");
        }else {
            if(!data.user.wallet) {
                onShowLowCoinsPopup()
                return;
            }
            let balance = data.user.wallet?.amount
            if(balance < 10){
                onShowLowCoinsPopup()
            }else {
                dispatch(selectDonorForEmail(donor));
                navigate("/mailbox");
            }
        }
    };

    const addToCompare = (ladyId) => {
        if (!user || (data.user && !data.user?.isLoggedIn)) {
            navigate("/login");
        }else {
            const isAlreadyCompared = comparedDonors.includes(ladyId);
    
            props.compare(ladyId);
    
            if (isAlreadyCompared) {
                const updatedComparedDonors = comparedDonors.filter(
                    (id) => id !== ladyId
                );
                setComparedDonors(updatedComparedDonors);
            } else {
                sendToDataLayer('Compare_donor', 'client', 'Compare_donor', 'user', ladyId)
                setComparedDonors([...comparedDonors, ladyId]);
            }
        }
    };

    const addToFav = async (ladyId) => {
        if (!user || (data.user && !data.user?.isLoggedIn)) {
            navigate("/login");
        } else {
            const isAlreadyFav = favDonors.includes(ladyId);
            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                    "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
                },
            };
            const { data } = await axios.post(
                `https://api.surrogacy-platform.com/api/donors/like/${ladyId}`,
                {},
                config
            );
    
            if (isAlreadyFav) {
                const updatedFavDonors = favDonors.filter((id) => id !== ladyId);
                setFavDonors(updatedFavDonors);
            } else {
                sendToDataLayer('Like_donor', 'client', 'Like_donor', 'user', ladyId)
                setFavDonors([...favDonors, ladyId]);
            }
        }
    };

    const accessChat = async (donorId, path, hasVideo) => {
        if(!data.user.wallet) {
            onShowLowCoinsPopup()
            return;
        }
        let balance = data.user.wallet?.amount;

        if(hasVideo && balance < 100){
            onShowLowCoinsPopup()
            return;
        }

        if(!hasVideo && balance < 20){
            onShowLowCoinsPopup()
            return;
        }
        
        if (!user || (data.user && !data.user?.isLoggedIn)) {
            navigate("/login");
        }else {
            const data = {
                senderClientId: user.userId,
                donorId: donorId,
                type: "CHAT",
                hasVideo: hasVideo,
            };
            dispatch(
                sendConversationRequest(data, (response, error) => {
                    if (response) {
                        if(hasVideo){
                            const videoChatData = {
                                chatId: response.item.id,
                                userId: user.userId,
                                donorId: donorId,
                                name: userInfo?.firstName,
                                photo: userInfo?.userAvatar,
                                donorName: props.lady.name
                            }
                            socket.emit('request-to-translator', videoChatData)
                            dispatch(setChatConnection(true))
                            navigate(`/videochat/${response.item.id}`)

                        }else {
                            if (!chats.find((c) => c.id === response.item.id)) setChats([response.item, ...chats]);
                            setSelectedChat(response.item.id);
                            navigate(`${path}${response.item.id}`);
                        }
                    } else {
                        console.error("Ошибка:", error);
                    }
                })
            );
        }
    };

    const onShowLowCoinsPopup = () => {
        dispatch(fetchPopup({
            popupType: 'CoinsModal',
            props: {
                className: "small-balance",
                text: 'Apologies, you do not have enough coins for this',
                buttonText: "Top up your balance",
                type: "small-balance",
                onTopUp: () => {
                    navigate('/coins')
                }
            },
        }))
    }

    return (
        <div className={classNames(classes["card"], classes[props.className])}>
            <div className={classes["card_image_block"]}>
                <div className={classes["card_image"]}>
                    <div>
                        <img
                            src={
                                props.lady.userAvatar
                                    ? `${props.lady.userAvatar}`
                                    : "/cdn/images/ladies/photo.jpg"
                            }
                            alt={`${props.lady.name} `}
                        />
                    </div>
                </div>
                <div className={classes["card_top_block"]}>{label}</div>
                <div className={classes["card_top_buttons"]}>
                    <div
                        className={classNames(
                            classes["image"],
                            classes["save_to_bookmark"],
                            savedeDonors.includes(props.lady.id) && classes.card_checked
                        )}
                        onClick={() => addToSaved(props.lady.id)}
                    >
                        {savedeDonors.includes(props.lady.id) ? (
                            <Icon
                                id="icon-bookmark-checked"
                                className="icon-bookmark-checked"
                            />
                        ) : (
                            <Icon id="icon-bookmark" className="icon-bookmark" />
                        )}
                    </div>
                    <div
                        className={classNames(
                            classes["image"],
                            classes["add_to_compare"],
                            compared && compared.includes(props.lady.id) && classes.card_checked
                        )}
                        onClick={() => addToCompare(props.lady.id)}
                    >
                        {compared && compared.includes(props.lady.id) ? (
                            <Icon id="icon-compare-checked" className="icon-compare" />
                        ) : (
                            <Icon id="icon-compare" className="icon-compare" />
                        )}
                    </div>
                </div>
                <Link
                    className={classes["card_image_description"]}
                    to={props.lady?.linkToPage}
                >
                    <span className={classes["card_more"]}>More Information</span>
                </Link>
            </div>
            <div className={classes["card_data_block"]}>
                <div className={classes["card_name_block"]}>
                    <div className={classes["card_name"]}>
                        {props.lady.name}
                        {props.lady.age ? "," : null}
                        <span className={classes["card_age"]}>
                            {props.lady.age ? props.lady.age : null}
                        </span>
                        {
                            onlineDonors && onlineDonors.find(item => item.donorId == props.lady.id) && (
                                <div className={classes["card_online"]}></div>
                            )
                        }
                    </div>
                    <div
                        className={classNames(classes["image"], classes["heart"])}
                        onClick={() => addToFav(props.lady.id)}
                    >
                        {favDonors.includes(props.lady.id) ? (
                            <Icon id="icon-heart-filled" className="icon-heart-filled" />
                        ) : (
                            <Icon id="icon-heart" className="icon-heart" />
                        )}
                    </div>
                </div>
                <div className={classes["card_appearance"]}>
                    <div>
                        <span>Hair:</span>
                        <span>{props.lady?.hairColor}</span>
                    </div>
                    <div>
                        <span>Eyes:</span>
                        <span>{props.lady?.eyeColor}</span>
                    </div>
                </div>
                {
                    showButtons && (
                        <div className={classes["card_hover_buttons"]}>
                            <button onClick={() => emailHandler(props.lady)}>Email</button>
                            <div className={classes["card_hover_buttons_separator"]}></div>
                            <button
                                onClick={() => accessChat(props.lady.id, "/textchat/", false)}
                            >
                                InviteChat
                            </button>
                        </div>
                    )
                }
                <div className={classes["card_bottom_buttons"]}>
                    <button
                        className={classes["card_message_button"]}
                        onClick={() => setShowButtons(!showButtons)}
                    >
                        Message
                    </button>
                    <VideochatButton type="card" donorId={props.lady.id} donorName={props.lady.name} />
                </div>
            </div>
        </div>
    );
};

export default LadyCard;
