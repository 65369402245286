import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Button from '../../../ui/Button';
import Icon from '../../../ui/Icon';
import Input from '../../../ui/Input';

import classes from "../style/formAuth.module.scss";
import { registrationRequest } from '../../../actions/authActions';
import { sendToDataLayer } from '../../../utils/dataLayer';

const SignUp = ({onShowVerificationBlock, showFormType}) => {
    const dispatch = useDispatch();
    const errorMessage = useSelector(state => state.auth.error);

    const handleClick = (type) => {
        showFormType(type)
    }

    const onSuccessSignup = () => {
        onShowVerificationBlock(true)
        sendToDataLayer('Sign_in', 'client', 'Sign_in', 'user', 1)         
    }

    const onSubmit = async (data) => {
        try {
            const registerData = {
                email: data.email,
                phoneNumber: data.phone,
                password: data.password,
                appType: "WEB",
                role: "user"
            }
            await dispatch(registrationRequest({
                ...registerData,
                onSuccess: () => onSuccessSignup(),
            }));
            
        } catch (error){
            console.error('error', error);
        }
    };

    const schema = yup
        .object({
            email: yup
                .string()
                .email()
                .required(),
            phoneNumber: yup.string(),
            password: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })
    

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input
                type="email"
                name="email"
                useFormEl={register('email')}
                placeholderNormal="E-mail"
                withError={errors.email && true}
                errorText={errors?.email?.type == 'email' ? 'Invalid Email format' : 'Please, enter your Email'}
                placeHolder="Your Email"
            />
            <Input
                type="text"
                name="phoneNumber"
                useFormEl={register('phoneNumber')}
                placeholderNormal="Enter Your Phone"
                addProps={{ autoComplete: 'off' }}
                withError={errors.phoneNumber && true}
                errorText="Please, enter Your Phone"
                placeHolder="Your Phone"
            />
            <Input
                type="password"
                name="password"
                useFormEl={register('password')}
                placeholderNormal="Password"
                addProps={{ autoComplete: 'off' }}
                withError={errors.password && true}
                errorText="Please, enter password"
                placeHolder="Password"
            />
            {
                errorMessage && (
                    <div className={classes["error-block"]}>
                        <Icon id="icon-alert" />
                        <p>{errorMessage.message}</p>
                    </div>

                )
            }
            <div className={classes["forgot_password"]} onClick={() => handleClick('forgot')}>
                Forgot password?
            </div>
            <Button type="submit" addClasses={["button"]}>
                sign up
            </Button>
        </form>
    );
}

export default SignUp;
