import React, {useState, useEffect, useRef, useContext} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isAndroid, isIOS, isMobile, isTablet } from 'react-device-detect';

import PageWrap from '../../app/components/PageWrap';
import Notifications from '../../components/Notifications';
import TextChatList from './components/TextChatList';
import TextChatRoom from './components/TextChatRoom';

import classNames from 'classnames';
import classes from './style/textchat.module.scss';
import EmptyChat from '../../components/EmptyChat';
import { SocketContext } from '../../context/socket';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import { fetchPopup } from '../../actions/modalActions';
import { fetchChatsRequest, fetchNotificationsRequest } from '../../actions/textChatActions';

const Textchat = () => {
    const socket = useContext(SocketContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const textChats = useSelector((state) => state.textChats.textChatsList);
    const [notificationsShow, setNotificationsShow] = useState(false);
    const [notificationCount, setNotificationCount] = useState(null);
    const [showTextChatRoom, setShowTextChatRoom] = useState(true);
    const chatBlockRef = useRef(null);
    const { chatId } = useParams();

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    useEffect(() => {
        if (!textChats) {
            dispatch(fetchChatsRequest());
            dispatch(fetchNotificationsRequest());
        }
    }, [dispatch, textChats]);

    useEffect(() => {
        if(!userInfo || (user && !user?.isLoggedIn)){
            navigate('/login')
        }
    }, [user, userInfo]);

    useEffect(() => {
        if(user) {
            if(user.wallet){
                setNotificationCount(user.wallet?.amount)
            }else {
                setNotificationCount(0)
            }
        }
      }, [user, chatId]);

    useEffect(() => {
        if(isTablet || isMobile){
            setShowTextChatRoom(false)
        }
        setNotificationsShow(false)
        window.addEventListener('resize', handleResize);
        document.addEventListener('DOMContentLoaded', handleResize);
        setNotificationsShow(false)

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('DOMContentLoaded', handleResize);
        };
    }, []);

    useEffect(() => {
        if ((notificationCount && notificationCount < 20) || notificationCount == 0) {
            setNotificationsShow(true);
        }
    }, [notificationCount]);

    useEffect(() => {
        if(chatId){
            if ((notificationCount && notificationCount < 20) || notificationCount == 0){
                onShowNotifyPopup()
            }else {
                setShowTextChatRoom(true)
            }
        }else(
            setShowTextChatRoom(false)
        )
    }, [chatId, notificationCount]);

    useEffect(() => {
        generateChatHeight();
    }, [notificationsShow]);

    const handleResize = () => {
        if (chatBlockRef.current) {
            generateChatHeight();
        }
    };

    const generateChatHeight = () => {
        const headerMenu = document.querySelector('.mobile_header_menu');
        const navBar = document.querySelector('.nav_bar');
        const notificationBlock = document.querySelector('.notification_section');
        const chatBlock = chatBlockRef.current;

        let navBarMarginTop = 0;
        let chatBlockMarginTop = 0;
        const innerHeight = window.innerHeight;
        const innerWidth = window.innerWidth;

        if (innerWidth <= 1024) {
            navBarMarginTop = 8;
        }

        if (notificationBlock && notificationBlock.classList.contains('display-none') && innerWidth > 1024) {
            chatBlockMarginTop = 30;
        }

        if (chatBlock && (headerMenu || navBar) && notificationBlock) {
            chatBlock.style.height = String(
                innerHeight - (headerMenu ? headerMenu.offsetHeight : 0) - navBar.offsetHeight - notificationBlock.offsetHeight - navBarMarginTop - chatBlockMarginTop
            ) + 'px';
        }
    };

    const onShowNotifyPopup = () => {
        dispatch(fetchPopup({
            popupType: 'CoinsModal',
            props: {
                className: "small-balance",
                text: 'Apologies, you do not have enough coins for this',
                buttonText: "Top up your balance",
                type: "small-balance",
                onTopUp: () => {
                    navigate('/coins')
                }
            },
        }))
    }

    return (
        <PageWrap>
            {notificationsShow ? <Notifications count={notificationCount}/> : null}
            <section className={classNames(classes['chat'], notificationsShow ? classes['chat_notification_open'] : '')}>
                <div className="container">
                    <div className={classNames(classes['chat_block'], (isMobile && isIOS) && classes['on-ios'], (isMobile && isAndroid) && classes['on-android'])} ref={chatBlockRef}>
                        {
                            isTablet || isMobile ? (
                                !showTextChatRoom ? (
                                    <TextChatList chatId={chatId} notificationCount={notificationCount} onShowNotifyPopup={onShowNotifyPopup} />
                                ) : (
                                    <TextChatRoom chatId={chatId} />
                                )
                        ) : (
                            <>
                                <TextChatList chatId={chatId} notificationCount={notificationCount} onShowNotifyPopup={onShowNotifyPopup} />
                                {
                                    !showTextChatRoom ? (
                                        <EmptyChat text="Let’s start Chat with Donors" />
                                        ) : (
                                        <TextChatRoom chatId={chatId} />
                                    )
                                }
                            </>
                        )
                        }
                    </div>
                </div>
            </section>
        </PageWrap>
    );
};

export default Textchat;
