import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import PageWrap from '../../app/components/PageWrap';
import Icon from '../../ui/Icon';
import Modal from '../../components/Modal';

import classes from './style/clientProfilePage.module.scss'
import classNames from 'classnames';
import { fetchUserRequestAcction } from '../../actions/userActions';
import { fetchPopup } from '../../actions/modalActions';

const ClientProfilePage = () => {
    const dispatch = useDispatch()
    let [searchParams, setSearchParams] = useSearchParams();
    const edit = searchParams.get('edit');
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const userInfo = useSelector((state) => state.user.user)

    useEffect(() => {
        if(!edit && userInfo && userInfo.isAuthenticated && !userInfo.firstName){
            window.location.href = '/profile?edit=showEdit'
        }
        if(!user || (userInfo && !userInfo.isLoggedIn)) {
            window.location.href = '/login'
        }
    }, [userInfo, edit, user]);

    useEffect(() => {
        if(edit && edit == 'showEdit'){
            dispatch(fetchPopup({
                popupType: 'EditClientProfile',
                hideClose: true,
                props: {
                    className: "edit-profile",
                    onClose: () => {
                        if(searchParams.has('edit')) {
                            searchParams.delete('edit'); 
                            setSearchParams(searchParams)
                        }
                    }
                },
            }))
        }
    }, [edit]);

    const onEditProfile = () => {
        dispatch(fetchPopup({
            popupType: 'EditClientProfile',
            props: {
                className: "edit-profile"
            },
        }))
    }

    useEffect(() => {
        if(user && !userInfo){
            dispatch(fetchUserRequestAcction(user.userId))
        }
    }, []);

    if(!userInfo){
        return null
    }

    return (
        <PageWrap>
            <section className={classes["personal"]}>
                <div className={classNames("container", classes["personal_row"])}>
                    <div className={classes["personal_gallery"]}>
                        <div>
                            <div>
                                <div className={classes["personal_main_photo"]}>
                                    <div className={classes["personal_carousel_image_container"]}>
                                        {
                                            userInfo?.userAvatar ? (
                                                <div>
                                                    <div>
                                                        <img className={classes["personal_main_photo_image"]} src={userInfo.userAvatar} alt="..." />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={classes['avatar-container']}>
                                                    <div className={classNames(classes["avatar"], "avatar")}>
                                                        {
                                                            userInfo?.firstName ? (
                                                                `${userInfo?.firstName.charAt(0)}${userInfo?.lastName.charAt(0)}`
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes["personal_info"]}>
                        <div className={classNames(classes["profile_name"])}>
                            <div className={classNames(classes['name-row'], userInfo.isOnline && classes['online'])}>
                                {
                                    userInfo.firstName && (
                                        <p className={classes['name']}>{userInfo.firstName.charAt(0).toUpperCase()+userInfo.firstName.slice(1)} {userInfo.lastName && userInfo.lastName.charAt(0).toUpperCase()+userInfo.lastName.slice(1)}{userInfo.age && ","}</p>
                                    )
                                }
                                {
                                    userInfo.age && (
                                        <p className={classes['age']}>{userInfo.age}</p>
                                    )
                                }
                            </div>
                            <div className={classes["edit_block"]} onClick={onEditProfile}>
                                <Icon id="icon-edit" className="icon-edit" />
                                <div className={classes["edit"]}>Edit</div>
                            </div>
                        </div>
                        <div className={classes["location"]}>
                            <Icon id="icon-location" className="icon-location" />
                            <span className={classes["location_country"]}>{userInfo.country}</span>
                            <span className={classes["location_dot"]} />
                            <span className={classes["location_city"]}>{userInfo.city}</span>
                        </div>
                        <div className={classes["data"]}>
                            <ul className={classNames(classes["main_info"], classes["client_profile"])}>
                                {
                                    userInfo.firstName && (
                                        <li>
                                            <p>
                                                First Name
                                            </p>
                                            <p>
                                                {userInfo.firstName.charAt(0).toUpperCase()+userInfo.firstName.slice(1)}
                                            </p>
                                        </li>
                                    )
                                }
                                {
                                    userInfo.lastName && (
                                        <li>
                                            <p>
                                                Last Name
                                            </p>
                                            <p>
                                                {userInfo.lastName.charAt(0).toUpperCase()+userInfo.lastName.slice(1)}
                                            </p>
                                        </li>
                                    )
                                }
                                {
                                    userInfo.age && (
                                        <li>
                                            <p>
                                                Age
                                            </p>
                                            <p>
                                                {userInfo.age}
                                            </p>
                                        </li>
                                    )
                                }
                                {
                                    userInfo.country && (
                                        <li>
                                            <p>
                                                Country
                                            </p>
                                            <p>
                                                {userInfo.country}
                                            </p>
                                        </li>
                                    )
                                }
                                {
                                    userInfo.city && (
                                        <li>
                                            <p>
                                                City
                                            </p>
                                            <p>
                                                {userInfo.city}
                                            </p>
                                        </li>
                                    )
                                }
                                {
                                    userInfo.timezoneTimestamp && (
                                        <li>
                                            <p>
                                                Timezone
                                            </p>
                                            <p>
                                                {userInfo.timezoneTimestamp}
                                            </p>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {
                userInfo.aboutMe && (
                    <section className={classes["about_info"]}>
                        <div className="container">
                            <div className={classes["card"]}>
                                <h3>
                                    About me
                                </h3>
                                <p>
                                    {userInfo.aboutMe}
                                </p>
                            </div>
                        </div>
                    </section>
                )
            }
        </PageWrap>
    );
}

export default ClientProfilePage;
