import React, { useState, useCallback } from 'react';
import classes from './style/filter.module.scss';
import Button from '../../ui/Button';
import Icon from '../../ui/Icon';
import RangeSlider from '../../ui/RangeSlider';
import classNames from 'classnames';
import { fetchDonorsRequest } from '../../actions/donorActions';
import { useDispatch } from 'react-redux';
import { sendToDataLayer } from '../../utils/dataLayer';

const RadioInput = ({ name, id, value, label, checked, onChange }) => (
    <label htmlFor={id} className={classNames(classes["label-filter"], classes['radio'])}>
        <input
            type="radio"
            name={name}
            id={id}
            className={`${classes["radio_input"]} ${classes["radio"]} ${checked ? classes["radio_checked"] : ''}`}
            defaultValue={value}
            defaultChecked={checked}
            onChange={onChange}
        />
        <i>{label}</i>
    </label>
);

const CheckboxImageInput = ({ name, id, value, checked, onChange, imgSrc }) => (
    <label htmlFor={id} className={classNames('tooltip', classes["label-filter"])}>
        <input
            type="checkbox"
            name={name}
            id={id}
            className={classes["radio_image"]}
            value={value}
            checked={checked}
            onChange={onChange}
        />
        <img loading="lazy" src={imgSrc} alt="" className={checked ? classes["radio_image_checked"] : ''} />
        <span className="tooltiptext">{value}</span>
    </label>
);

const CheckboxInput = ({ id, name, value, checked, onChange, label }) => (
    <div className='checkbox-row'>
        <input
            type="checkbox"
            name={name}
            id={id}
            className={classNames(classes["checkbox_input"], checked ? classes['checkbox_checked'] : '')}
            value={value}
            checked={checked}
            onChange={onChange}
        />
        <label htmlFor={id} className={classes["label-filter"]}>
            {label}
        </label>
    </div>
);

const SelectInput = ({ name, id, options, defaultValue, onChange }) => (
    <div className={classes["select-filter"]}>
        <select name={name} id={id} className={classes["select"]} defaultValue={defaultValue} onChange={onChange}>
            {options.map(option => (
                <option key={option} value={option}>{option}</option>
            ))}
        </select>
        <div className={classes["image"]}>
            <Icon id="icon-arrow-down" className="icon-arrow-down" />
        </div>
    </div>
);

const Filter = ({onLadiesSearch, onClearSearch}) => {
    const dispatch = useDispatch()
    const [availability, setAvailability] = useState('all');
    const [ethnicity, setEthnicity] = useState([]);
    const [selectedEyeColors, setSelectedEyeColors] = useState([]);
    const [selectedHairColors, setSelectedHairColors] = useState([]);
    const [selectedBloodTypes, setSelectedBloodTypes] = useState([]);
    const [selectedRhFactors, setSelectedRhFactors] = useState([]);
    const [selectedDonorTypes, setSelectedDonorTypes] = useState([]);
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState('Any');
    const [selectedEducation, setSelectedEducation] = useState('Any');
    const [ageRange, setAgeRange] = useState([18, 35]);

    const availabilityOptions = [
        { label: 'All', value: 'all' },
        { label: 'Online', value: 'online' },
        { label: 'Offline', value: 'offline' },
    ];

    const ethnicityOptions = [
        // { label: 'Asian', value: 'asian', id: 'asian', name: 'asian' },
        // { label: 'Caucasian', value: 'caucasian', id: 'caucasian', name: 'caucasian' },
        // { label: 'East Indian', value: 'east_indian', id: 'east_indian', name: 'east_indian' },
        { label: 'European', value: 'european' , id: 'european', name: 'european'},
        { label: 'Slavic', value: 'slavic', id: 'slavic', name: 'slavic' },
    ];

    const eyeColorOptions = [
        { label: 'Black', value: 'Black', imgSrc: '/cdn/images/filter/eyes/black.svg' },
        { label: 'Brown', value: 'Brown', imgSrc: '/cdn/images/filter/eyes/brown.svg' },
        { label: 'Grey', value: 'Grey', imgSrc: '/cdn/images/filter/eyes/grey.svg' },
        { label: 'Dark green', value: 'Dark green', imgSrc: '/cdn/images/filter/eyes/darkgreen.svg' },
        { label: 'Green', value: 'Green', imgSrc: '/cdn/images/filter/eyes/green.svg' },
        { label: 'Blue', value: 'Blue', imgSrc: '/cdn/images/filter/eyes/blue.svg' },
    ];

    const hairColorOptions = [
        { label: 'Black', value: 'Black', imgSrc: '/cdn/images/filter/hair/black.png' },
        { label: 'Brown', value: 'Brown', imgSrc: '/cdn/images/filter/hair/brown.png' },
        { label: 'Dark blonde', value: 'Dark blonde', imgSrc: '/cdn/images/filter/hair/gold.png' },
        { label: 'Blonde', value: 'Blonde', imgSrc: '/cdn/images/filter/hair/blonde.png' },
        // { label: 'Red', value: 'Red', imgSrc: '/cdn/images/filter/hair/red.png' },
        { label: 'Red', value: 'Red', imgSrc: '/cdn/images/filter/hair/darkred.png' },
    ];

    const bloodTypeOptions = [
        { id: 'blood_a', name: 'blood_a', value: 'blood_a', label: 'A' },
        { id: 'blood_b', name: 'blood_b', value: 'blood_b', label: 'B' },
        { id: 'blood_ab', name: 'blood_ab', value: 'blood_ab', label: 'AB' },
        { id: 'blood_0', name: 'blood_0', value: 'blood_0', label: '0' },
    ];

    const rhFactorOptions = [
        { id: 'any', name: 'rh', value: 'any', label: 'Any' },
        { id: 'rh-', name: 'rh', value: 'rh-', label: 'Rh-' },
        { id: 'rh+', name: 'rh', value: 'rh+', label: 'Rh+' },
    ];

    const handleAvailabilityChange = useCallback((e) => {
        setAvailability(e.target.value);
    }, []);

    const handleEthnicityChange = useCallback((e) => {
        const value = e.target.value;
        if (ethnicity.includes(value)) {
            setEthnicity(ethnicity.filter(type => type !== value));
        } else {
            setEthnicity([...ethnicity, value]);
        }
    }, [ethnicity]);

    const handleEyeColorChange = useCallback((e) => {
        const value = e.target.value;
        if (selectedEyeColors.includes(value)) {
            setSelectedEyeColors(selectedEyeColors.filter(color => color !== value));
        } else {
            setSelectedEyeColors([...selectedEyeColors, value]);
        }
    }, [selectedEyeColors]);

    const handleHairColorChange = useCallback((e) => {
        const value = e.target.value;
        if (selectedHairColors.includes(value)) {
            setSelectedHairColors(selectedHairColors.filter(color => color !== value));
        } else {
            setSelectedHairColors([...selectedHairColors, value]);
        }
    }, [selectedHairColors]);

    const handleBloodTypeChange = useCallback((e) => {
        const value = e.target.value;
        if (selectedBloodTypes.includes(value)) {
            setSelectedBloodTypes(selectedBloodTypes.filter(type => type !== value));
        } else {
            setSelectedBloodTypes([...selectedBloodTypes, value]);
        }
    }, [selectedBloodTypes]);

    const handleRhFactorChange = useCallback((e) => {
        const value = e.target.value;
        if (selectedRhFactors.includes(value)) {
            setSelectedRhFactors(selectedRhFactors.filter(factor => factor !== value));
        } else {
            setSelectedRhFactors([...selectedRhFactors, value]);
        }
    }, [selectedRhFactors]);

    const handleDonorTypeChange = useCallback((e) => {
        const value = e.target.value;
        if (selectedDonorTypes.includes(value)) {
            setSelectedDonorTypes(selectedDonorTypes.filter(type => type !== value));
        } else {
            setSelectedDonorTypes([...selectedDonorTypes, value]);
        }
    }, [selectedDonorTypes]);

    const handleMaritalStatusChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedMaritalStatus(selectedValue);
    };

    const handleEducationChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedEducation(selectedValue);
    };

    const handleAgeChange = (newRange) => {
        setAgeRange(newRange);
    };

    const getSearchesData = () => {
        sendToDataLayer('Filter_form_use', 'client', 'Filter_form_use', 'filter', 1)
        const searchData = {
            availability,
            ethnicity,
            eyeColors: selectedEyeColors,
            hairColors: selectedHairColors,
            bloodTypes: selectedBloodTypes,
            rhFactors: selectedRhFactors,
            donorTypes: selectedDonorTypes,
            maritalStatus: selectedMaritalStatus,
            education: selectedEducation,
            ageFrom: ageRange[0],
            ageTo: ageRange[1],
        };
        setAgeRange([18,35])

        const searchParamsString = Object.entries(searchData)
            .filter(([key, value]) => {
                return (
                    value !== undefined &&
                    value !== null &&
                    (Array.isArray(value) ? value.length > 0 : value !== 'Any' && value !== 'any')
                );
            })
            .map(([key, value]) => {
                const encodedValue = Array.isArray(value)
                    ?JSON.stringify(value)
                    : value;

                return `${key}=${encodedValue}`;
            })
            .join('&');

        onLadiesSearch(searchParamsString)
    }

    const clearSearch = () => {
        setAvailability('all');
        setEthnicity([]);
        setSelectedEyeColors([]);
        setSelectedHairColors([]);
        setSelectedBloodTypes([]);
        setSelectedRhFactors([]);
        setSelectedDonorTypes([]);
        setSelectedMaritalStatus('Any');
        setSelectedEducation('Any');
        setAgeRange([18, 35]);
        onClearSearch()
    }

    return (
        <div className={classes["search_filter_card"]}>
            <div className={classes["search_filter_card_block"]}>
                <span>Availability
                    {/* <i> (120)</i> */}
                </span>
                {availabilityOptions.map(option => (
                    <RadioInput
                        key={option.value}
                        name="availability"
                        id={`availability${option.value}`}
                        value={option.value}
                        label={option.label}
                        checked={availability === option.value}
                        onChange={handleAvailabilityChange}
                    />
                ))}
            </div>
            <div className={classes["search_filter_card_block"]}>
                <span>Ethnicity
                    {/* <i> (120)</i> */}
                </span>
                {ethnicityOptions.map(option => (
                    <CheckboxInput
                        key={option.id}
                        id={option.id}
                        name={option.name}
                        value={option.value}
                        label={option.label}
                        checked={ethnicity.includes(option.value)}
                        onChange={handleEthnicityChange}
                    />
                ))}
            </div>
            <RangeSlider onRangeChange={handleAgeChange} />
            <div className={classes["search_filter_card_block"]}>
                <span>Eye Color
                    {/* <i> (12)</i> */}
                </span>
                <div className={classes["radio_image_row"]}>
                    {eyeColorOptions.map(option => (
                        <CheckboxImageInput
                            key={option.value}
                            id={`eyeColor${option.value}`}
                            name="eye_color"
                            value={option.value}
                            checked={selectedEyeColors.includes(option.value)}
                            onChange={handleEyeColorChange}
                            imgSrc={option.imgSrc}
                            label={option.label}
                        />
                    ))}
                </div>
            </div>
            <div className={classes["search_filter_card_block"]}>
                <span>Hair Color
                    {/* <i> (22)</i> */}
                </span>
                <div className={classes["radio_image_row"]}>
                    {hairColorOptions.map(option => (
                        <CheckboxImageInput
                            key={option.value}
                            id={`hair${option.value}`}
                            name="hair_color"
                            value={option.value}
                            checked={selectedHairColors.includes(option.value)}
                            onChange={handleHairColorChange}
                            imgSrc={option.imgSrc}
                            label={option.label}
                        />
                    ))}
                </div>
            </div>

            <div className={classes["search_filter_card_block"]}>
                <span>Blood Type
                    {/* <i> (54)</i> */}
                </span>
                {bloodTypeOptions.map(option => (
                    <CheckboxInput
                        key={option.id}
                        id={option.id}
                        name={option.name}
                        value={option.value}
                        checked={selectedBloodTypes.includes(option.value)}
                        onChange={handleBloodTypeChange}
                        label={option.label}
                    />
                ))}
            </div>
            <div className={classes["search_filter_card_block"]}>
                <span>Rh Factor
                    {/* <i> (24)</i> */}
                </span>
                {rhFactorOptions.map(option => (
                    <CheckboxInput
                        key={option.id}
                        id={option.id}
                        name={option.name}
                        value={option.value}
                        checked={selectedRhFactors.includes(option.value)}
                        onChange={handleRhFactorChange}
                        label={option.label}
                    />
                ))}
            </div>
            <div className={classes["search_filter_card_block"]}>
                <span>Marital Status</span>
                <SelectInput name="marital" id="marital" options={['Any', 'Married', 'Unmarried']} value={selectedMaritalStatus} onChange={handleMaritalStatusChange} />
            </div>
            {/* Level of Education */}
            <div className={classes["search_filter_card_block"]}>
                <span>Level of Education</span>
                <SelectInput name="education" id="education" options={["Student", "High School", "College", "University", "Postgraduate"]} value={selectedEducation} onChange={handleEducationChange} />
            </div>
            {/* Buttons */}
            <div>
                <Button addClasses={['button', 'capitalize']} onClick={getSearchesData}>Search</Button>
                <Button addClasses={['button', 'flat-rounded', 'capitalize']} onClick={clearSearch}>Clear</Button>
            </div>
        </div>
    );
};

export default Filter;
