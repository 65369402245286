import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import AsideMenu from "../../components/AsideMenu";
import TopMenu from "../../components/TopMenu";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserRequestAcction, getClientOrderDonorRequest, getLastOrderCoinsRequest } from "../../actions/userActions";
import useEventObserver from "../../hooks/useEventObserver";
import { fetchPopup } from "../../actions/modalActions";
import { sendToDataLayer } from "../../utils/dataLayer";

const PageWrap = ({ fullWide, children, withTopMenu }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { roomId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const payment = searchParams.get('payment');
    const paymentPackageDonor = searchParams.get('paymentPackageDonor');
    
    const [hide, setHide] = useState(false);
    const [toggleMenuShow, setToggleMenuShow] = useState(false);
    
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const userInfo = useSelector((state) => state.user.user)
    const hideMenu = useSelector((state) => state.globalState.hideMenu);
    const orderDonorInfo = useSelector((state) => state.user.orderDonorInfo);
    const lastOrderCoinPack = useSelector((state) => state.user.lastOrderCoinPack);
    
    useEventObserver();

    useEffect(() => {
        if(userInfo && payment && payment == 'success'){
            if(lastOrderCoinPack && lastOrderCoinPack.name == 'basic'){
                sendToDataLayer('Buy_coins_basic', 'coins', 'Buy_coins_basic', 'user', 200)
            }
            if(lastOrderCoinPack && lastOrderCoinPack.name == 'standart'){
                sendToDataLayer('Buy_coins_standart', 'coins', 'Buy_coins_standart', 'standart', 400)
            }
            if(lastOrderCoinPack && lastOrderCoinPack.name == 'advanced'){
                sendToDataLayer('Buy_coins_advance', 'coins', 'Buy_coins_advance', 'advance', 1200)
            }
            dispatch(
                fetchPopup({
                    popupType: "SuccessOrder",
                    props: {
                        className: "success",
                        text: `You have successfully topped up your coin balance.<br />You now have ${userInfo.wallet.amount} coins.`,
                        onClose: () => {
                            if(searchParams.has('payment')) {
                                searchParams.delete('payment'); 
                                setSearchParams(searchParams)
                            }
                        }
                    },
                })
            );
        }
        if(userInfo && payment && payment == 'failed'){
            dispatch(
                fetchPopup({
                    popupType: "FailedOrder",
                    props: {
                        className: "success",
                        text: `Oops, your payment did not go through.`,
                        onClose: () => {
                            if(searchParams.has('payment')) {
                                searchParams.delete('payment'); 
                                setSearchParams(searchParams)
                            }
                        }
                    },
                })
            );
        }
    }, [payment, user]);

    useEffect(() => {
        if(userInfo && paymentPackageDonor && paymentPackageDonor == 'success'){
            dispatch(getClientOrderDonorRequest(userInfo.id))
        }
        if(userInfo && payment && payment == 'success'){
            dispatch(getLastOrderCoinsRequest(userInfo.id))
        }
    }, [payment, userInfo]);

    useEffect(() => {
        if(userInfo && paymentPackageDonor && paymentPackageDonor == 'success' && orderDonorInfo){
            if(orderDonorInfo?.order.name == 'standart') {
                sendToDataLayer('Payment_package_Standart', 'package', 'Payment_package_Standart', 'standart', orderDonorInfo?.order.price)
            }
            if(orderDonorInfo?.order.name == 'comfort') {
                sendToDataLayer('Payment_package_comfort', 'package', 'Payment_package_comfort', 'comfort', orderDonorInfo?.order.price)
            }
            if(orderDonorInfo?.order.name == 'vip') {
                sendToDataLayer('Payment_Package_VIP', 'package', 'Payment_Package_VIP', 'VIP', orderDonorInfo?.order.price)
            }
            dispatch(
                fetchPopup({
                    popupType: "SuccessOrder",
                    props: {
                        className: "success",
                        text: `Your payment was successful.<br />Thank you for ordering the <span>${orderDonorInfo?.order.name} package</span> <br />for donor <span>${orderDonorInfo?.donorName}</span>!`,
                        onClose: () => {
                            if(searchParams.has('paymentPackageDonor')) {
                                searchParams.delete('paymentPackageDonor'); 
                                setSearchParams(searchParams)
                            }
                        }
                    },
                })
            );
        }
    }, [orderDonorInfo, payment, userInfo]);

    useEffect(() => {
        if(userInfo && !userInfo.isAuthenticated){
            showConfirmPopup()
        }
        if(userInfo && !location.pathname.includes('/profile') && userInfo.isAuthenticated && !userInfo?.firstName){
            window.location.href = '/profile?edit=showEdit'
        }
    }, [userInfo]);

    useEffect(() => {
        if (hideMenu) {
            document.body.classList.add('menu-hidden');
        } else {
            document.body.classList.remove('menu-hidden');
        }
      
          return () => {
            document.body.classList.remove('menu-hidden');
          };
    }, [hideMenu]);

    useEffect(() => {
        if(user && !userInfo){
            dispatch(fetchUserRequestAcction(user.userId))
        }
    }, [dispatch]);

    useEffect(() => {
        if(location.pathname.includes("/login") || location.pathname.includes("/textchat") || location.pathname.includes("/videochat") || location.pathname.includes("/mailbox") || location.pathname.includes("/settings")) {
            setHide(true)
        }
    }, [location]);

    const showMenu = () => {
        setToggleMenuShow(!toggleMenuShow)
    }

    const showConfirmPopup = () => {
        dispatch(fetchPopup({
            popupType: 'ConfirmEmailPopup',
            hideClose: true, 
            props: {
                className: "confirm-email"
            },
        }))
    }

    return (
        <>
        {
            fullWide ? (
                <>
                {withTopMenu && (
                    <TopMenu fullWidth={withTopMenu} inChat={roomId} showMenu={showMenu} toggleMenuShow={toggleMenuShow} />
                )}
                <main>
                    {children}
                </main>
                </>
            ) : (
                <>
                    {!isMobile ? <AsideMenu inChat={roomId} /> : null}
                    <TopMenu inChat={roomId} showMenu={showMenu} toggleMenuShow={toggleMenuShow} />
                    <main>
                        {children}
                    </main>
                    {
                        !hide ? (
                            <Footer />
                        ) : null
                    }
                    {
                        toggleMenuShow ? (
                            <div className="overlay" onClick={showMenu} />
                        ) : null
                    }
                </>
            )
        }
        <Modal />
        </>
    );
};

export default PageWrap;
