import React from 'react';
import { Link } from 'react-router-dom';

import classes from "../style/newsSection.module.scss";

const NewsSection = () => {
    return (
        <section className={classes["news"]}>
            <div className="container">
                <h2>
                    News and special offers
                </h2>
                <div className={classes["news_container"]}>
                    <div className={classes["news_card"]}>
                        <div className={classes["news_card_image"]}>
                            <img src="/cdn/images/main-page/news_image_1.jpg" alt="" />
                        </div>
                        <div className={classes["news_card_description"]}>
                            <span>
                                12.04.2024
                            </span>
                            <h3>
                                Are genes passed on from the surrogate mother to the child?
                            </h3>
                            <p>
                                Wisdom says: "It is not the mother who gave birth, but the mother who brought up". It seems that mankind has decided to follow the folk wisdom and surrogacy was invented.
                            </p>
                            <Link to="/blog/are-genes-passed-on-from-the-surrogate-mother-to-the-child">
                                Read About
                            </Link>
                        </div>
                    </div>
                    <div className={classes["news_card"]}>
                        <div className={classes["news_card_image"]}>
                            <img src="/cdn/images/main-page/news_image_2.jpg" alt="" />
                        </div>
                        <div className={classes["news_card_description"]}>
                        <span>
                            15.05.2024
                        </span>
                            <h3>
                                What will the baby look like when using donor oocytes?
                            </h3>
                            <p>
                                Any baby gets half of its genes from its mother and half from its father. Therefore, if you use donor eggs, your baby will probably look like two drops of water like his or her father.
                            </p>
                            <Link to="/blog/what-will-the-baby-look-like-when-using-donor-oocytes">
                                Read About
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NewsSection;
