import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDonorByIdRequest, getDonorPhotosRequest, getDonorVideosRequest } from "../../actions/donorActions";

import PageWrap from "../../app/components/PageWrap";
import InfoSection from "./components/InfoSection";
import ProfileSection from "./components/ProfileSection";
import InterviewSection from "./components/InterviewSection";
import PhotosSection from "./components/PhotosSection";
import VideosSection from "./components/VideosSection";
import LettersSection from "./components/LettersSection";
import Tabs from "../../components/Tabs";
import Loading from "../../components/Loading";
import { selectDonorForEmail } from "../../actions/emailActions";
import { fetchPopup } from "../../actions/modalActions";
import { sendToDataLayer } from "../../utils/dataLayer";

const SingleDonorPage = () => {
    const { donorParams } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const donorId = donorParams.split("-")[1]

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const user = useSelector((state) => state.user.user);
    const singleDonorInfo = useSelector((state) => state.donors.singleDonorInfo);
    const loading = useSelector((state) => state.donors.loading);
    const error = useSelector((state) => state.donors.error);
    const donorPhotos = useSelector((state) => state.donors.donorPhotos);
    const donorVideos = useSelector((state) => state.donors.donorVideos);

    const [activeTab, setActiveTab] = useState({
        title: "Profile",
        label: "profile",
        show: true
    },);
    const [primarydonorVideos, setPrimarydonorVideos] = useState(null);

    useEffect(() => {
        dispatch(fetchDonorByIdRequest(donorId));
    }, [dispatch, donorId]);

    useEffect(() => {
        dispatch(getDonorPhotosRequest(donorId));
        dispatch(getDonorVideosRequest(donorId));
      }, [donorId, dispatch]);

      useEffect(() => {
        if (donorVideos && donorVideos[donorId]?.length) {
            const mergedArray = [].concat(...Object.values(donorVideos));
            const filteredArray = mergedArray.filter(
                (obj) => obj.album === "primary"
            );
            setPrimarydonorVideos(filteredArray[0]);
        }
    }, [donorVideos]);

    const showComponents = () => {
        switch (activeTab.label) {
            case "profile":
                return <ProfileSection donor={singleDonorInfo} />;
            case "interview":
                return <InterviewSection donor={singleDonorInfo} primarydonorVideos={primarydonorVideos} />;
            case "photos":
                return <PhotosSection donor={singleDonorInfo} />;
            case "videos":
                return <VideosSection donor={singleDonorInfo} />;
            default:
                return null;
        }
    };

    const onChangeTab = (tab) => {
        setActiveTab(tab);
        if(tab.label == 'photos') {
            sendToDataLayer('Click_photo_page', 'client', 'Click_photo_page', 'user', donorId)              
        }
    };

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const tabsList = [
        {
            title: "Profile",
            label: "profile",
            show: true
        },
        {
            title: "Interview",
            label: "interview",
            show: primarydonorVideos
        },
        {
            title: "Photos",
            label: "photos",
            show: donorPhotos && donorPhotos[donorId]?.length > 0
        },
        {
            title: "Videos",
            label: "videos",
            show: donorVideos && donorVideos[donorId]?.length > 0
        },
    ];

    const onShowComposeLetter = () => {
        sendToDataLayer('Button_send_Email', 'client', 'Button_send_Email', 'user', donorId)          
        if (!userInfo || (user && !user?.isLoggedIn)) {
            navigate("/login");
        } else {
            if(!user.wallet) {
                onShowLowCoinsPopup()
                return;
            }
            let emailCoins = user.wallet?.amount;
            if (emailCoins < 10) {
                onShowLowCoinsPopup()
            } else {
                dispatch(selectDonorForEmail(singleDonorInfo));
                navigate("/mailbox");
            }
        }
    };

    const onShowLowCoinsPopup = () => {
        dispatch(fetchPopup({
            popupType: 'CoinsModal',
            props: {
                className: "small-balance",
                text: 'Apologies, you do not have enough coins for this',
                buttonText: "Top up your balance",
                type: "small-balance",
                onTopUp: () => {
                    navigate('/coins')
                }
            },
        }))
    }

    return (
        <PageWrap>
            {singleDonorInfo && (
                <div className="container">
                    <InfoSection
                        donor={singleDonorInfo}
                        onShowComposeLetter={onShowComposeLetter}
                        onShowLowCoinsPopup={onShowLowCoinsPopup}
                    />
                    <Tabs
                        tabsList={tabsList}
                        onChangeTab={onChangeTab}
                        activeTab={activeTab}
                        hideInMobile={false}
                    />
                    {showComponents()}
                </div>
            )}
        </PageWrap>
    );
};

export default SingleDonorPage;
