import { FETCH_CHATS_FAILURE, FETCH_CHATS_REQUEST, FETCH_CHATS_SUCCESS, FETCH_MESSAGES_FAILURE, FETCH_MESSAGES_REQUEST, FETCH_MESSAGES_SUCCESS, FETCH_NOTIFICATIONS_FAILURE, FETCH_NOTIFICATIONS_REQUEST, FETCH_NOTIFICATIONS_SUCCESS, RESET_MESSAGES, UPDATE_NOTIFICATION_FAILURE, UPDATE_NOTIFICATION_REQUEST, UPDATE_NOTIFICATION_SUCCESS } from "../constants/actionTypes";

const initialState = {
    textChatsList: null,
    messagesById: null,
    notifications: [],
    loading: false,
    error: null,
  };
  
  const textChatReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CHATS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case FETCH_CHATS_SUCCESS:
        return {
          ...state,
          textChatsList: action.payload,
          loading: false,
          error: null,
        };
  
      case FETCH_CHATS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

      case FETCH_MESSAGES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case FETCH_MESSAGES_SUCCESS:
        const { chatId, messages, totalMessagesCount } = action.payload;
        const existingChat = state.messagesById[chatId];
      
        // Ensure messages is an array
        const newMessages = Array.isArray(messages) ? messages : [];
      
        // Merge the existing messages with the new ones
        const updatedMessages = existingChat
          ? [...existingChat.messages, ...newMessages]
          : newMessages;
      
        return {
          ...state,
          messagesById: {
            ...state.messagesById,
            [chatId]: {
              messages: updatedMessages,
              totalMessagesCount,
            },
          },
        };
      case RESET_MESSAGES:
        return {
          ...state,
          messagesById: {
            ...state.messagesById,
            [action.payload]: {
              messages: [],
              totalMessagesCount: 0,
            },
          },
        };
  
      case FETCH_MESSAGES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

      case FETCH_NOTIFICATIONS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case FETCH_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          notifications: action.payload,
          loading: false,
          error: null,
        };
  
      case FETCH_NOTIFICATIONS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case UPDATE_NOTIFICATION_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
        
      case UPDATE_NOTIFICATION_SUCCESS:
        return {
          ...state,
          notifications: action.payload.messageNotification,
          loading: false,
          error: null,
        };

      case UPDATE_NOTIFICATION_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
      default:
        return state;
    }
  };
  
  export default textChatReducer;