import React, { useEffect, useState } from "react";
import Fancybox from "../../../components/Fancybox/Fancybox";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import { useNavigate } from "react-router-dom";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getDonorPhotosRequest, getDonorVideosRequest } from "../../../actions/donorActions";
import { fetchPopup } from "../../../actions/modalActions";

import classes from "../style/videosSection.module.scss";

const VideosSection = ({donor}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const user = useSelector((state) => state.user.user);
    const donorVideos = useSelector((state) => state.donors.donorVideos);

    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [isLowBalance, setIsLowBalance] = useState(false);

    useEffect(() => {
        if(user && selectedPhoto) {
            setIsLowBalance(user.wallet.amount < selectedPhoto.price)
        }
    }, [user, selectedPhoto]);

    const gotToLogin = () => {
        navigate('/login')
    }

    const buyPhoto = (photo) => {
        if(!userInfo || (user && !user.isLoggedIn)){
            gotToLogin()
        }else {
            setSelectedPhoto(photo);
            dispatch(fetchPopup({
                popupType: 'CoinsModal',
                props: {
                    onBuyPrivatePhoto: onBuyPrivatePhoto,
                    className: "buy-photo",
                    text: user.wallet.amount < photo.price
                        ? `This video is private!<br />You have to top up your balance!<br /><span>Note: The price of this video (${photo.price} coins) exceeds your balance.</span>`
                        : `This video is private!<br />The cost is ${photo.price} coins`,
                    buttonText: user.wallet.amount < photo.price ? "Top up your balance" : "Buy",
                    price: photo.id,
                    type: "buy-photo"
                },
            }))
        }
    };

    const closeModal = () => {
        setSelectedPhoto(null);
    };

    const onBuyPrivatePhoto = () => {
        closeModal()
        dispatch(getDonorVideosRequest(donor.id))
    };

    function generateSrc(video) {
        if ((video.isHidden && (user && !user?.isLoggedIn)) || (video.isHidden && !userInfo)) {
            return `https://storage.surrogacy-platform.com/images/donors/${video.donorId}/${video.id}_hidden.jpg`;
        } else if(!video.isBought) {
            return `${video.preview}`;
        }else {
            return `${video.preview}`;
        }
    }

    function generateSrcVideo(video) {
        if ((video.isHidden && (user && !user?.isLoggedIn)) || (video.isHidden && !userInfo)) {
            return `https://storage.surrogacy-platform.com/images/donors/${video.donorId}/${video.id}_hidden.jpg`;
        } else if(!video.isBought) {
            return `${video.preview}`;
        }else {
            return `${video.url}`;
        }
    }

    return (
        <section className={classes["videos"]}>
            <Fancybox className={classes["images"]}>
                {donorVideos && donorVideos[donor.id] &&
                    donorVideos[donor.id].map((video, index) => (
                        <div key={index} className={classes["image"]}>
                            <div className={classes["image_wrapper"]}>
                                <a
                                    data-fancybox
                                    href={generateSrcVideo(video)}
                                    className={classes["image"]}
                                >
                                    <img src={generateSrc(video)} alt="" />
                                {
                                    (video.price > 0 && video.album == "private" && !video.isBought) || (video.isHidden && !user.isLoggedIn) || (video.isHidden && !userInfo) ? null : (
                                        <img className={classes['icon-play']} src="/cdn/images/donor-profile/play.png" alt="icon play"  />
                                    )
                                }
                                </a>
                                {
                                    video.isHidden && !userInfo ? (
                                        <div className={classes["photos_cover_login"]} onClick={gotToLogin}>
                                            <span>Login</span>
                                        </div>
                                    ) : (
                                        <>
                                            {video.price > 0 && video.album == "private" && !video.isBought && (
                                                <div
                                                    className={classes["photos_cover_privat"]}
                                                    onClick={() => buyPhoto(video)}
                                                >
                                                    <span>Private</span>
                                                </div>
                                            )}
                                            {video.isHidden && !user.isLoggedIn && (
                                                <div className={classes["photos_cover_login"]} onClick={gotToLogin}>
                                                    <span>Login</span>
                                                </div>
                                            )}
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    ))}
            </Fancybox>
        </section>
    );
}

export default VideosSection;
