import React from 'react';
import classes from "../style/mapSection.module.scss";

const MapSection = () => {
    return (
        <section className={classes["map"]}>
            <div className="container">
                <h2 className={classes["title"]}>
                    Our clients map
                </h2>
                <div className={classes["map_block"]}>
                    <div className={classes["cards"]}>
                        <div className={classes["map_card"]}>
                            <div className={classes["title"]}>
                                27+
                            </div>
                            <div className={classes["description"]}>
                                Countries where our children live
                                <div className={classes["additional"]}>
                                    <span>Boys</span>
                                    <span>Girls</span>
                                </div>
                            </div>
                        </div>
                        <div className={classes["map_card"]}>
                            <div className={classes["title"]}>
                                68+
                            </div>
                            <div className={classes["description"]}>
                                Happy Clients
                            </div>
                        </div>
                        <div className={classes["map_card"]}>
                            <div className={classes["title"]}>
                                275+
                            </div>
                            <div className={classes["description"]}>
                                The Best Eggs Donors
                            </div>
                        </div>
                    </div>
                    <img src="/cdn/images/main-page/map-2.png" alt="map" />
                </div>
            </div>
        </section>
    );
}

export default MapSection;
