import React, { useCallback, useContext, useEffect, useState } from "react";

import classes from "./style/composeLetter.module.scss";
import classNames from "classnames";
import Icon from "../../ui/Icon";
import Button from "../../ui/Button";
import { SocketContext } from "../../context/socket";
import Select from "react-dropdown-select";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchEmailsRequest,
    fetchMessagesForEmailRequest,
    getEmailsSentRequest,
    sendMailRequest,
} from "../../actions/emailActions";
import { getSavedDonorsRequest } from "../../actions/donorActions";
import { sendConversationRequest } from "../../actions/globalActions";

const ComposeLetter = ({
    closeCompose,
    isReply,
    inDonorPage,
    senderLetterInfo,
    currentPage
}) => {
    const userInfo = useSelector((state) => state.user.user);
    const selectedDonor = useSelector((state) => state.emails.selectedDonor);

    const socket = useContext(SocketContext);
    const dispatch = useDispatch();

    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState(null);
    const [newMessage, setNewMessage] = useState("");
    const [newSubject, setNewSubject] = useState("");
    const [targetDonor, setTargetDonor] = useState(null);
    const [donorsList, setDonorsList] = useState(null);
    const [successSent, setSuccessSent] = useState(false);
    const [emptySubject, setEmptySubject] = useState(false);

    useEffect(() => {
        if(newSubject){
            setEmptySubject(false)
        }
    }, [newSubject]);

    const typingHandlerSubject = (e) => {
        setNewSubject(e.target.value);
    };

    const typingHandler = (e) => {
        setNewMessage(e.target.value);
    };

    useEffect(() => {
        if (selectedDonor) {
            setTargetDonor(selectedDonor);
        }
    }, [selectedDonor]);

    const handleFileChange = (event) => {
        const fileInput = event.target;
        if (fileInput.files.length > 0) {
            const selectedFile = fileInput.files[0];
            setFileName(selectedFile.name);
            setFile(selectedFile);
        } else {
            setFileName("");
            setFile(null);
        }
    };

    const handleRemoveFile = () => {
        setFileName("");
        setFile(null);
    };

    const donorSet = (donor) => {
        setTargetDonor(donor[0]);
    };

    const sendMail = () => {
        if (userInfo) {
            if (isReply) {
                const emailData = {
                    conversationId: parseInt(senderLetterInfo.id),
                    senderId: userInfo.id,
                    message: newMessage,
                    type: "EMAIL",
                };

                dispatch(
                    sendMailRequest(emailData, (response, error) => {
                        if (response) {
                            socket.emit("new message", response.data);
                            dispatch(
                                fetchMessagesForEmailRequest(1, 10, senderLetterInfo.id)
                            );
                        } else {
                            console.error("Failed to send email:", error);
                        }
                    })
                );
                closeCompose();
            } else {
                const data = {
                    senderClientId: userInfo.id,
                    donorId: targetDonor.id,
                    type: "EMAIL",
                    subject: newSubject,
                };
                if(newSubject) {
                    dispatch(
                        sendConversationRequest(data, (response, error) => {
                            if (response) {
                                const emailData = {
                                    conversationId: response.item.id,
                                    senderId: userInfo.id,
                                    message: newMessage,
                                    type: "EMAIL",
                                };
                                dispatch(
                                    sendMailRequest(emailData, (response, error) => {
                                    if (response) {
                                        socket.emit("new message", response.data);
                                        dispatch(fetchEmailsRequest({page: currentPage, limit:10} ));
                                        setSuccessSent(true)
                                        setTimeout(() => {
                                            closeCompose();
                                        }, 2000);
                                    } else {
                                        console.error("Failed to send email:", error);
                                    }
                                }))
                            } else {
                                console.error("Error:", error);
                            }
                        })
                    );
                }else {
                    setEmptySubject(true)
                }
            }
        }
    };

    useEffect(() => {
        if(!donorsList || donorsList.length === 0){
            dispatch(getSavedDonorsRequest((response, error) => {
                if (response && response.length) {
                    const donorsListArr = response.map(item=>{return {
                        value: item.id,
                        label: item.name,
                        id:item.id,
                    }});
                    setDonorsList(donorsListArr)
                } else {
                    console.error("Failed to send email:", error);
                    return;
                }
            }))
        }
    }, [donorsList, dispatch]);

    return (
        <div
            className={classNames(
                classes["email_window"],
                classes["email_modal_window"],
                isReply ? classes["in_reply"] : "",
                successSent && classes['success-sent']
            )}
        >
            <div className={classes["email_window_header"]}>
                {
                    !successSent && (
                        isReply ? (
                            <h5>Reply To {senderLetterInfo && senderLetterInfo.name}</h5>
                        ) : (
                            <h5>New Message</h5>
                        )
                    )
                }
                <div className={classes["email_window_buttons"]}>
                    <div
                        className={classNames(classes["image"], classes["new_email_close"])}
                        onClick={closeCompose}
                    >
                        <Icon className="icon-x" id="icon-x" />
                    </div>
                </div>
            </div>
            {
                successSent && !isReply ? (
                    <p>Your message has been successfully sent!</p>
                ) : (
                    <>
                        {!isReply ? (
                            <>
                                {!inDonorPage ? (
                                    <div className={classes["email_window_to"]}>
                                        <h5>To:</h5>
                                        {(donorsList && !targetDonor) && (
                                            <Select
                                                options={donorsList}
                                                onChange={(values) => donorSet(values)}
                                                searchable={true}
                                                placeholder="Select from your liked donors"
                                                color="#7367F0"
                                                separator={true}
                                            />
                                        )}
                                        {(!donorsList && !targetDonor) && (
                                            <p className={classes["empty-donor-list"]}>Please, add donor to your saved list</p>
                                        )}
                                        {
                                            targetDonor && (
                                                <div className={classes["email_window_to_list"]}>
                                                    <div className={classes["email_window_pill"]}>
                                                        <span>{targetDonor.name || targetDonor.label}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : null}
                                <div className={classes["email_window_subject"]}>
                                    <h5>Subject:</h5>
                                    <input placeholder="Your subject" onChange={typingHandlerSubject} className={classNames(emptySubject && classes['error'])} />
                                    {emptySubject && (<p className={classes['error-text']}>Subject is empty</p>)}
                                </div>
                            </>
                        ) : null}
                        <div className={classes["email_window_body"]}>
                            <textarea
                                placeholder="Type message…"
                                id="body"
                                value={newMessage}
                                onChange={typingHandler}
                            ></textarea>
                        </div>
                        <div className={classes["email_window_footer"]}>
                            <div className={classes["email_window_left_buttons"]}>
                                <Button
                                    addClasses={["button", "default", "capitalize"]}
                                    onClick={sendMail}
                                    className={classes["send-btn"]}
                                >
                                    Send
                                </Button>
                                {/* {!fileName && (
                                    <label htmlFor="input_files_new">
                                        <div
                                            className={classNames(classes["image"], classes["add_file"])}
                                        >
                                            <Icon className="icon-paperclip" id="icon-paperclip" />
                                        </div>
                                        <input
                                            type="file"
                                            className={classNames(
                                                "display-none",
                                                classes["input_files_new"]
                                            )}
                                            id="input_files_new"
                                            onChange={handleFileChange}
                                        />
                                    </label>
                                )} */}
                                {/* {fileName && (
                                    <div className={classes["file-name"]}>
                                        <p>{fileName}</p>
                                        <div
                                            className={classes["remove-file"]}
                                            onClick={handleRemoveFile}
                                        >
                                            <Icon className="icon-x" id="icon-x" />
                                        </div>
                                    </div>
                                )} */}
                                <div className={classes["added_file_list_new"]}></div>
                            </div>
                            {/* <div className={classes["email_window_right_buttons"]}>
                                <div
                                    className={classNames(classes["image"], classes["delete_message"])}
                                >
                                    <Icon className="icon-bin" id="icon-bin" />
                                </div>
                            </div> */}
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default React.memo(ComposeLetter);
