// import Stories from "../../../Components/Stories";
import LinkButton from "../../../ui/Button/LinkButton";
import { useNavigate } from 'react-router-dom';
// import {useNavigate} from 'react-router'

import classNames from "classnames";
import classes from '../style/topSection.module.scss';
import axios from "axios";
// import {loginRequest} from "../../../actions/authActions";
// import {jwtDecode} from "jwt-decode";

const TopSection = () => {
    return (
        <section className={classes["welcome"]}>
            <div className={classNames(classes["welcome_main"], "container")}>
                {/* <Stories /> */}
                <div className={classes["welcome_main_title_block"]}>
                    <div className={classes["welcome_main_title"]}>
                        <h1>
                        Welcome 
                        to the world's 
                        first open database of egg donors!
                        </h1>
                        <h2>Surrogacy Platform - is a service to find and select egg donors and surrogate mothers.</h2>
                        <LinkButton addClasses={['capitalize']} to="/donors">View our donors</LinkButton>
                    </div>
                    <div className={classes["welcome_main_cards"]}>
                        <img src="/cdn/images/main-page/top-banner.png" alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TopSection;
